var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var SL,TL,UL,Jha,Kha,YL,Lha,aM,bM,cM,dM,Mha,eM,fM,hM,iM,VL,jM,Nha,kM,Oha,lM,mM,nM,Pha,oM,pM,Qha,qM,Rha,rM,sM,Sha,uM,Tha,Uha,Vha,wM,xM,yM,zM,AM,BM,CM,DM,Wha,EM,FM,GM,IM,JM,Xha,KM,LM,MM,NM,Yha,OM,PM,QM,RM,Zha,SM,TM,UM,VM,$ha,WM,aia,XM,YM,ZM,bia,$M,aN,bN,cN,eN,fN,cia,gN,hN,dia,iN,jN,kN,lN,mN,eia,nN,fia,oN,pN,rN,gia,sN,tN,uN,hia,xN,yN,iia,jia,zN,BN,CN,DN,kia,GN,HN,lia,mia,nia,IN,JN,KN,LN,MN,NN,oia,ON,pia,PN,QN,SN,UN,VN,WN,XN,qia,ria,ZN,sia,tia,$N,aO,bO,cO,dO,uia,eO,via,wia,fO,gO,hO,iO,jO,
xia,yia,zia,kO,Aia,lO,Bia,nO,oO,Cia,qO,rO,sO,tO,Dia,wO,Eia,Fia,xO,yO,zO,Gia,AO,BO,CO,DO,Hia,FO,GO,HO,IO,JO,Iia,KO,LO,Jia,MO,NO,OO,PO,QO,RO,TO,Kia,UO,Lia,VO,WO,XO,Mia,Nia,Oia,ZO,$O,aP,Pia,bP,cP,dP,eP,Qia,Ria,gP,Sia,Tia,hP,iP,jP,kP,lP,Uia,mP,nP,oP,pP,Via,qP,rP,sP,tP,vP,Wia,wP,xP,Xia,yP,zP,AP,Yia,BP,Zia,DP,$ia,EP,FP,GP,HP,IP,JP,KP,aja,bja,cja,LP,dja,MP,NP,eja,fja,OP,PP,QP,gja,RP,hja,SP,ija,jja,TP,kja,lja,mja,WP,XP,nja,YP,ZP,aQ,oja,bQ,pja,cQ,dQ,eQ,qja,rja,fQ,gQ,hQ,iQ,sja,kQ,lQ,tja,mQ,nQ,oQ,pQ,rQ,sQ,tQ,
uQ,uja,vQ,wQ,vja,xQ,wja,yQ,zQ,AQ,xja,BQ,CQ,yja,zja,DQ,EQ,FQ,Aja,Bja,GQ,Cja,Dja,Eja,IQ,Fja,KQ,Gja,Hja,LQ,MQ,Ija,Jja,Kja,NQ,OQ,PQ,QQ,RQ,SQ,TQ,Lja,UQ,VQ,Mja,WQ,Nja,Oja,Pja,XQ,YQ,ZQ,Qja,$Q,aR,Rja,bR,Sja,cR,Tja,Uja,eR,fR,gR,hR,iR,Vja,jR,kR,Wja,lR,mR,nR,oR,pR,qR,rR,sR,Xja,tR,uR,wR,xR,yR,zR,AR,BR,CR,DR,ER,FR,Yja,GR,HR,IR,JR,KR,Zja,LR,MR,NR,OR,$ja,QR,aka,bka,SR,cka,TR,dka,UR,eka,fka,VR,gka,WR,ZR,$R,hka,ika,dS,eS,jka,kka,fS,gS,hS,iS,jS,lka,kS,lS,nS,oS,mka;
$CLJS.RL=function(a){var b=$CLJS.yo.g(a,null),c=$CLJS.kF(b,$CLJS.Ms,function(d){return $CLJS.xn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.pl,b,$CLJS.Dj,k,$CLJS.Ss,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
SL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};TL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.An($CLJS.yo.g($CLJS.pl.h(a),null));if($CLJS.n(b)){var e=SL($CLJS.st.h(b),c);$CLJS.n(e)?(e=$CLJS.Iq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:SL($CLJS.qt.h(b),c)}return null};UL=function(a,b){return $CLJS.vd(a)||$CLJS.Jl(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Jha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.pl),d=$CLJS.J.g(a,$CLJS.mj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Ss,$CLJS.tea),f=$CLJS.J.j(b,$CLJS.oea,!0),k=$CLJS.J.g(b,$CLJS.bC),l=$CLJS.J.j(b,$CLJS.$B,$CLJS.YB),m=TL(a,$CLJS.Oq.h(c),k,b);if($CLJS.n(m))return m;m=TL(a,$CLJS.dt.h(c),k,b);if($CLJS.n(m))return m;m=TL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=TL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=TL(a,$CLJS.Oq.h(c),
l,b);if($CLJS.n(m))return m;m=TL(a,$CLJS.dt.h(c),l,b);if($CLJS.n(m))return m;d=TL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=TL(a,function(){var t=$CLJS.fo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?TL(a,e.h?e.h($CLJS.WB):e.call(null,$CLJS.WB),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?TL(a,e.h?e.h($CLJS.WB):e.call(null,$CLJS.WB),l,b):f};
Kha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.ml.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.pl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.bC);l=$CLJS.J.j(l,$CLJS.$B,$CLJS.YB);k=$CLJS.Oq.h(k);f=SL($CLJS.ZB.h(k),f);f=$CLJS.n(f)?f:SL($CLJS.ZB.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Jha(b,c)],null)};
$CLJS.WL=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Dj);a=$CLJS.J.g(b,$CLJS.Ss);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.pea,$CLJS.Pi),f=$CLJS.J.j(d,$CLJS.rea,Kha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Pi,m);l=e.h?e.h(l):e.call(null,l);return VL(k,c,t,l)},null,a):null};
YL=function(a,b,c){var d=$CLJS.zA($CLJS.q($CLJS.aE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.aE,$CLJS.XL,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.ZL=function(a){return YL($CLJS.xk,a,function(){return $CLJS.lF.h(a)})};Lha=function(){var a=$L;return YL($CLJS.Ms,a,function(){var b=$CLJS.lF.h(a),c=$CLJS.RL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
aM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};bM=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,aM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xr,aM(c)],null);default:return aM(a)}}else return aM(a)};
cM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
dM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(cM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,bM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,bM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.gt(2,2,b))}())],null)};Mha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
eM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.Ni,Mha,$CLJS.qt,["valid instance of one of these MBQL clauses: ",$CLJS.Bt(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
fM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QL],null)],null)};$CLJS.gM={};hM={};iM={};$CLJS.XL=function XL(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=XL.j?XL.j(k,f,c):XL.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
VL=function VL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=UL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.XB.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=UL(f,c),d=VL.v?VL.v(b,l,k,d):VL.call(null,b,l,k,d),$CLJS.Ol(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Jl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qea],null),VL.v?VL.v(f,b,c,d):VL.call(null,f,b,c,d)):$CLJS.n($CLJS.XB.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.XB,!0],null))};jM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Nha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
kM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Oha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);lM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);mM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);nM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Pha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);oM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);pM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Qha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);qM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Rha=new $CLJS.M(null,"from","from",1815293044);rM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);sM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Sha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.tM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);uM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Tha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Uha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.vM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Vha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);wM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
xM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);yM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);zM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);AM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);BM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
CM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);DM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Wha=new $CLJS.M(null,"lon-max","lon-max",1590224717);EM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);FM=new $CLJS.r(null,"stddev","stddev",775056588,null);GM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.HM=new $CLJS.M(null,"snippet","snippet",953581994);IM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);JM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Xha=new $CLJS.M(null,"lat-field","lat-field",-830652957);KM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);LM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
MM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);NM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Yha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);OM=new $CLJS.M("location","country","location/country",1666636202);PM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
QM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);RM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Zha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);SM=new $CLJS.M(null,"unary","unary",-989314568);TM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
UM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);VM=new $CLJS.r(null,"ceil","ceil",-184398425,null);$ha=new $CLJS.M(null,"lon-min","lon-min",-787291357);WM=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);aia=new $CLJS.M(null,"match","match",1220059550);XM=new $CLJS.r(null,"count-where","count-where",2025939247,null);YM=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
ZM=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);bia=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);$M=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);aN=new $CLJS.r(null,"sum","sum",1777518341,null);bN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);cN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.dN=new $CLJS.M("date","range","date/range",1647265776);eN=new $CLJS.r(null,"between","between",-1523336493,null);fN=new $CLJS.M(null,"clause-form","clause-form",1820463737);cia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);gN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);hN=new $CLJS.r(null,"field","field",338095027,null);dia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
iN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);jN=new $CLJS.r(null,"not-null","not-null",313812992,null);kN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);lN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);mN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);eia=new $CLJS.M(null,"template-tag","template-tag",310841038);
nN=new $CLJS.M(null,"invalid","invalid",412869516);fia=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);oN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);pN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.qN=new $CLJS.M(null,"context","context",-830191113);rN=new $CLJS.r(null,"get-year","get-year",704520253,null);
gia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);sN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);tN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);uN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.vN=new $CLJS.M(null,"parameters","parameters",-1229919748);hia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.wN=new $CLJS.M(null,"card","card",-1430355152);xN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);yN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);iia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);jia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);zN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.AN=new $CLJS.M("date","month-year","date/month-year",1948031290);
BN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);CN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);DN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.EN=new $CLJS.M(null,"joins","joins",1033962699);kia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.FN=new $CLJS.M(null,"source-field","source-field",933829534);GN=new $CLJS.r(null,"Field","Field",430385967,null);
HN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);lia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);mia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);nia=new $CLJS.M(null,"items","items",1031954938);IN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);JN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
KN=new $CLJS.M(null,"more","more",-2058821800);LN=new $CLJS.M(null,"first-clause","first-clause",-20953491);MN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);NN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);oia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);ON=new $CLJS.r(null,"contains","contains",-1977535957,null);
pia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);PN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);QN=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.RN=new $CLJS.M(null,"widget-type","widget-type",1836256899);SN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.TN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
UN=new $CLJS.r(null,"is-null","is-null",-356519403,null);VN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);WN=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);XN=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);qia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.YN=new $CLJS.M(null,"required","required",1807647006);ria=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
ZN=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);sia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);tia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);$N=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);aO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);bO=new $CLJS.M("string","contains","string/contains",1602423827);cO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
dO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);uia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);eO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);via=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);wia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
fO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);gO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);hO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);iO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);jO=new $CLJS.r(null,"share","share",1051097594,null);
xia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);yia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);zia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);kO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Aia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
lO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Bia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.mO=new $CLJS.M(null,"collection","collection",-683361892);nO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);oO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.pO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Cia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);qO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);rO=new $CLJS.r(null,"metric","metric",2049329604,null);sO=new $CLJS.r(null,"concat","concat",-467652465,null);tO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.uO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
Dia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.vO=new $CLJS.M("date","relative","date/relative",25987732);wO=new $CLJS.M("location","city","location/city",-1746973325);Eia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Fia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);xO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);yO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
zO=new $CLJS.M("number","between","number/between",97700581);Gia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);AO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);BO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);CO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);DO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.EO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Hia=new $CLJS.M(null,"metric-id","metric-id",-686486942);FO=new $CLJS.r(null,"*","*",345799209,null);GO=new $CLJS.r(null,"+","+",-740910886,null);HO=new $CLJS.r(null,"-","-",-471816912,null);IO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);JO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Iia=new $CLJS.M(null,"question","question",-1411720117);KO=new $CLJS.r(null,"asc","asc",1997386096,null);
LO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Jia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);MO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);NO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);OO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);PO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);QO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
RO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.SO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);TO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Kia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);UO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Lia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
VO=new $CLJS.r(null,"and","and",668631710,null);WO=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);XO=new $CLJS.r(null,"round","round",-645002441,null);Mia=new $CLJS.M(null,"to","to",192099007);$CLJS.YO=new $CLJS.M("date","single","date/single",1554682003);Nia=new $CLJS.M(null,"action-id","action-id",-1727958578);Oia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);ZO=new $CLJS.r(null,"exp","exp",1378825265,null);
$O=new $CLJS.r(null,"Filter","Filter",-424893332,null);aP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Pia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);bP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);cP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);dP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
eP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.fP=new $CLJS.M(null,"source-table","source-table",-225307692);Qia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Ria=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);gP=new $CLJS.r(null,"floor","floor",-772394748,null);Sia=new $CLJS.M(null,"middleware","middleware",1462115504);
Tia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);hP=new $CLJS.M(null,"requires-features","requires-features",-101116256);iP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);jP=new $CLJS.M(null,"clause-name","clause-name",-996419059);kP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);lP=new $CLJS.r(null,"now","now",-9994004,null);
Uia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);mP=new $CLJS.r(null,"not","not",1044554643,null);nP=new $CLJS.r(null,"avg","avg",1837937727,null);oP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);pP=new $CLJS.M(null,"max-results","max-results",-32858165);Via=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);qP=new $CLJS.r(null,"case","case",-1510733573,null);
rP=new $CLJS.r(null,"distinct","distinct",-148347594,null);sP=new $CLJS.r(null,"get-second","get-second",-425414791,null);tP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.uP=new $CLJS.M(null,"join-alias","join-alias",1454206794);vP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Wia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
wP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);xP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Xia=new $CLJS.M(null,"original","original",-445386197);yP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);zP=new $CLJS.r(null,"abs","abs",1394505050,null);AP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Yia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
BP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Zia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.CP=new $CLJS.M(null,"date","date",-1463434462);DP=new $CLJS.M(null,"second-clause","second-clause",-461435645);$ia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);EP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
FP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);GP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);HP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);IP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);JP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);KP=new $CLJS.r(null,"or","or",1876275696,null);
aja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);bja=new $CLJS.M(null,"constraints","constraints",422775616);cja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);LP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);dja=new $CLJS.M(null,"csv-download","csv-download",2141432084);MP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
NP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);eja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);fja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);OP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);PP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
QP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);gja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);RP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);hja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);SP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);ija=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
jja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);TP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);kja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);lja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);mja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.UP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.VP=new $CLJS.M(null,"database","database",1849087575);WP=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);XP=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);nja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);YP=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
ZP=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.$P=new $CLJS.M(null,"expressions","expressions",255689909);aQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);oja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);bQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
pja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);cQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);dQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);eQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);qja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);rja=new $CLJS.M(null,"action","action",-811238024);
fQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);gQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);hQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);iQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.jQ=new $CLJS.M(null,"native","native",-613060878);sja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);kQ=new $CLJS.M(null,"page","page",849072397);
lQ=new $CLJS.r(null,"length","length",-2065447907,null);tja=new $CLJS.M(null,"dashboard","dashboard",-631747508);mQ=new $CLJS.r(null,"get-week","get-week",752472178,null);nQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);oQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);pQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);rQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
sQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);tQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);uQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);uja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);vQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);wQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
vja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);xQ=new $CLJS.M(null,"internal","internal",-854870097);wja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);yQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);zQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
AQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);xja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);BQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);CQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);yja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
zja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);DQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);EQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);FQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Aja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Bja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);GQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.HQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);Cja=new $CLJS.M(null,"public-question","public-question",629369976);Dja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Eja=new $CLJS.M(null,"binary","binary",-1802232288);IQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.JQ=new $CLJS.M(null,"source-query","source-query",198004422);KQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Gja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Hja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);LQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);MQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Ija=new $CLJS.M(null,"executed-by","executed-by",-739811161);Jja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Kja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);NQ=new $CLJS.M(null,"amount","amount",364489504);OQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);PQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);QQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);RQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
SQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);TQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Lja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);UQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);VQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Mja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
WQ=new $CLJS.M("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Oja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);XQ=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);YQ=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
ZQ=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Qja=new $CLJS.M(null,"lat-min","lat-min",1630784161);$Q=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);aR=new $CLJS.r(null,"inside","inside",-681932758,null);Rja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);bR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Sja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);cR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Tja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Uja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.dR=new $CLJS.M(null,"card-id","card-id",-1770060179);eR=new $CLJS.M(null,"variadic","variadic",882626057);
fR=new $CLJS.r(null,"upper","upper",1886775433,null);gR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);hR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);iR=new $CLJS.r(null,"optional","optional",-600484260,null);Vja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);jR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
kR=new $CLJS.M(null,"sugar","sugar",-73788488);Wja=new $CLJS.M(null,"lat-max","lat-max",841568226);lR=new $CLJS.r(null,"power","power",702679448,null);mR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);nR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);oR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
pR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);qR=new $CLJS.r(null,"median","median",-2084869638,null);rR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);sR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Xja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);tR=new $CLJS.M(null,"y","y",-1757859776);uR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.vR=new $CLJS.M(null,"binning","binning",1709835866);wR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);xR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);yR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);zR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);AR=new $CLJS.r(null,"Reference","Reference",2024574086,null);BR=new $CLJS.M(null,"b","b",1482224470);
CR=new $CLJS.M(null,"a","a",-2123407586);DR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);ER=new $CLJS.r(null,"replace","replace",853943757,null);FR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Yja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);GR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
HR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);IR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);JR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);KR=new $CLJS.r(null,"segment","segment",675610331,null);Zja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);LR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
MR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);NR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);OR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);$ja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.PR=new $CLJS.M(null,"order-by","order-by",1527318070);QR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
aka=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);bka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.RR=new $CLJS.M(null,"condition","condition",1668437652);SR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);cka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);TR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
dka=new $CLJS.M(null,"card-name","card-name",-2035606807);UR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);eka=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);fka=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);VR=new $CLJS.r(null,"log","log",45015523,null);gka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
WR=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.YR=new $CLJS.M(null,"database_type","database_type",-54700895);ZR=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);$R=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.aS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.bS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.cS=new $CLJS.M("date","all-options","date/all-options",549325958);
hka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);ika=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);dS=new $CLJS.M("location","state","location/state",-114378652);eS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);jka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);kka=new $CLJS.M(null,"lon-field","lon-field",517872067);
fS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);gS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);hS=new $CLJS.M(null,"numeric","numeric",-1495594714);iS=new $CLJS.r(null,"variable","variable",1359185035,null);jS=new $CLJS.r(null,"lower","lower",-1534114948,null);lka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
kS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);lS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.mS=new $CLJS.M(null,"limit","limit",-1355822363);nS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);oS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);mka=new $CLJS.M(null,"pulse","pulse",-244494476);var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qE],null),qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zE],null),rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mE],null),sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iE],null),tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.uE],null),uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eL],null),nka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WK],null),oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RK],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.GI],null),wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null),xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null),yS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.Yt,null,$CLJS.oj,null,$CLJS.ci,null,$CLJS.Fi,null,$CLJS.xj,null,$CLJS.mk,null,$CLJS.Gj,null,$CLJS.ji,null,$CLJS.Fj,null,$CLJS.pi,null,$CLJS.zi,null],null),null),zS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.qk,null,$CLJS.TG,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.WG,null,$CLJS.ij,null,$CLJS.Ti,null],null),null),
AS=$CLJS.Zt.g(yS,zS),pka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"date bucketing unit"],null)],null),yS),qka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"time bucketing unit"],null)],null),zS),BS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"datetime bucketing unit"],null)],null),AS),CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),rka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,
1,[$CLJS.qt,"temporal extract unit"],null),$CLJS.XG,$CLJS.zi,$CLJS.mk,$CLJS.WI,$CLJS.qJ,$CLJS.rJ,$CLJS.Gj,$CLJS.Fi,$CLJS.vi,$CLJS.ij,$CLJS.UG],null),ska=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"datetime-diff unit"],null),$CLJS.TG,$CLJS.Ti,$CLJS.qk,$CLJS.Yt,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null),DS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"temporal-extract week extraction mode"],null),$CLJS.QI,$CLJS.ZI,$CLJS.dJ],null),ES=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ti,$CLJS.qk,$CLJS.Yt,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null),LS,uka,TS,US,VS,WS,XS,YS,ZS,KU,wka,LU,xka,yka,MU,zka,Aka,Bka;$CLJS.X(UM,dM($CLJS.$I,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.Sv],null),$CLJS.Cs],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,ES],null)])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UM],null);
$CLJS.X(yP,dM($CLJS.YI,$CLJS.H(["n",$CLJS.Cs,"unit",ES])));var tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yP],null);
$CLJS.X(oN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"valid :absolute-datetime clause",$CLJS.Ni,function(a){if($CLJS.Va(cM($CLJS.UI,a)))a=nN;else{a=$CLJS.hd(a);var b=$CLJS.ZL(vS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.CP:$CLJS.aJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[nN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CP,dM($CLJS.UI,
$CLJS.H(["date",vS,"unit",pka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,dM($CLJS.UI,$CLJS.H(["datetime",wS,"unit",BS]))],null)],null));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);$CLJS.X(DN,dM($CLJS.Ey,$CLJS.H(["time",xS,"unit",qka])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DN],null),IS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.qt,"date or datetime literal"],null),GS,wS,vS],null);
$CLJS.X(oM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.qt,"time literal"],null),HS,xS],null));$CLJS.X(fS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.qt,"temporal literal"],null),IS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null)],null));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fS],null);
$CLJS.X(Mja,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof GS?new $CLJS.Gc(function(){return GS},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,xQ,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eQ,"metabase/mbql/schema.cljc",69,$CLJS.UI,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:eQ}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof FS?new $CLJS.Gc(function(){return FS},$CLJS.od(hka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,
$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wP,"metabase/mbql/schema.cljc",29,$CLJS.$I,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$I,$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.Sv],null),$CLJS.Cs],null)),$CLJS.ri,$CLJS.U($CLJS.Qj,$CLJS.U(iR,UO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:wP}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof HS?new $CLJS.Gc(function(){return HS},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,xQ,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WR,"metabase/mbql/schema.cljc",27,$CLJS.Ey,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ey,$CLJS.Ey,$CLJS.U($CLJS.Qj,Lja),$CLJS.ri,$CLJS.U($CLJS.Qj,Dia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:WR}(),HS],null)])));
$CLJS.X(UR,dM($CLJS.Dj,$CLJS.H(["value",$CLJS.Hs,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YR,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,pS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dD,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EO,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,pS],null)],null)],null)],null)])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);$CLJS.X(DO,dM($CLJS.mB,$CLJS.H(["expression-name",pS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.ik],null)])));
LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DO],null);
uka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.BE);c=$CLJS.J.g(c,$CLJS.jE);return $CLJS.E.g(b,$CLJS.jE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.BE);c=$CLJS.J.g(c,$CLJS.AE);return $CLJS.E.g(b,$CLJS.AE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"binning strategy"],null),$CLJS.jE,$CLJS.AE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),sS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.AE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Bl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Wl)],null)],null)],null)],null)));$CLJS.MS=function MS(a){switch(arguments.length){case 1:return MS.h(arguments[0]);case 2:return MS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.MS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.pB);b=$CLJS.J.g(b,$CLJS.mG);return $CLJS.MS.g(a,b)};$CLJS.MS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.qC(a,$CLJS.RC)?yS:$CLJS.qC(a,$CLJS.QD)?zS:$CLJS.qC(a,$CLJS.qD)?AS:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.MS.A=2;
$CLJS.X(jR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,new $CLJS.h(null,
1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,BS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,pS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vR,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.MS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.BE)],null)],null));
$CLJS.X(LR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.pB);return"string"===typeof c?b:!0}],null)],null)}(dM($CLJS.gG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,uS,pS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jR],null)],null)]))));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);
$CLJS.X(LP,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(PN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.bS,$CLJS.U($CLJS.Qj,WM),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:zN}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},
$CLJS.od(gO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.pr,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",51,$CLJS.gG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:hN}(),NS],null)])));
$CLJS.OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LP],null);$CLJS.X(jM,dM($CLJS.WF,$CLJS.H(["aggregation-clause-index",$CLJS.Cs,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.ik],null)])));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jM],null);
$CLJS.X(QM,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof PS?new $CLJS.Gc(function(){return PS},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uR,"metabase/mbql/schema.cljc",23,$CLJS.WF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.WF,jka,$CLJS.U($CLJS.Qj,$CLJS.Cs),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:uR}(),PS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},
$CLJS.od(PN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.bS,$CLJS.U($CLJS.Qj,WM),
$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,$CLJS.ik))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:zN}(),LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},$CLJS.od(gO,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.pr,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,
$CLJS.ti,$CLJS.kk,$CLJS.ok],["0.39.0",$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",51,$CLJS.gG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:hN}(),NS],null)])));
var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null),RS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Dt,null,$CLJS.yG,null,$CLJS.zF,null,$CLJS.EG,null,$CLJS.GF,null,$CLJS.jG,null,$CLJS.QF,null,$CLJS.Jt,null,$CLJS.wG,null,$CLJS.iG,null,$CLJS.FG,null],null),null),SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);
$CLJS.X(qM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return"string"===typeof a?$CLJS.Zj:$CLJS.n(cM(RS,a))?JM:$CLJS.n(cM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[JM,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$CLJS.OS],null)],null));TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qM],null);
US=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.CF,null,$CLJS.pJ,null,$CLJS.Xr,null,$CLJS.lJ,null,$CLJS.iJ,null,$CLJS.UF,null,$CLJS.jt,null,$CLJS.yF,null,$CLJS.mJ,null,$CLJS.fJ,null,$CLJS.jG,null,$CLJS.BG,null,$CLJS.jJ,null,$CLJS.lG,null,$CLJS.EF,null,$CLJS.SI,null,$CLJS.Wr,null,$CLJS.HF,null,$CLJS.QF,null,$CLJS.MI,null,$CLJS.Ox,null,$CLJS.TI,null,$CLJS.eJ,null,$CLJS.XI,null,$CLJS.DG,null,$CLJS.eG,null],null),null);
VS=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.Tr,null,$CLJS.Nr,null,$CLJS.rs,null,$CLJS.ts,null,$CLJS.Pr,null,$CLJS.xG,null,$CLJS.xs,null,$CLJS.Ur,null,$CLJS.Rr,null],null),null);WS=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.Zn,null,$CLJS.vG,null,$CLJS.pG,null,$CLJS.hG,null,$CLJS.AG,null,$CLJS.CG,null,$CLJS.MF,null,$CLJS.SF,null,$CLJS.vM,null,$CLJS.NF,null,$CLJS.uG,null,$CLJS.nG,null,$CLJS.jk,null,$CLJS.Qx,null,$CLJS.aG,null,$CLJS.bG,null,$CLJS.tG,null],null),null);
XS=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.bJ,null,$CLJS.OI,null,$CLJS.nJ,null,$CLJS.Wr,null,$CLJS.hJ,null],null),null);YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bR],null);ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);$CLJS.$S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);$CLJS.aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hR],null);
$CLJS.X(oR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"numeric expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.St:$CLJS.n(cM(US,a))?eP:$CLJS.n(cM(WS,a))?$CLJS.WF:$CLJS.n(cM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.gG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.St,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[eP,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,
$CLJS.OS],null)],null));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null);
$CLJS.X(yQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"datetime expression argument",$CLJS.Ni,function(a){return $CLJS.n(cM(WS,a))?$CLJS.WF:$CLJS.n(cM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.n(cM(XS,a))?IN:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[IN,$CLJS.$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,IS,$CLJS.OS],null)],null)],null));
var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);
$CLJS.X(aQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"expression argument",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.St:$CLJS.Cd(a)?$CLJS.Gs:$CLJS.n(cM(VS,a))?pQ:$CLJS.n(cM(US,a))?eP:$CLJS.n(cM(XS,a))?IN:"string"===typeof a?$CLJS.Zj:$CLJS.n(cM(RS,a))?JM:$CLJS.n(cM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.St,$CLJS.Bl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Gs],null),new $CLJS.P(null,2,5,$CLJS.Q,[pQ,ZS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[eP,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[IN,$CLJS.$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[JM,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$CLJS.OS],null)],null));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aQ],null);$CLJS.X(ZP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.qt,"numeric expression arg or interval"],null),tka,bT],null));
var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZP],null);$CLJS.X(BM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"int greater than zero or numeric expression",$CLJS.Ni,function(a){return"number"===typeof a?$CLJS.St:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.St,sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,YS],null)],null));var vka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null);
$CLJS.X(JN,dM($CLJS.jG,$CLJS.H(["a",dT,"b",dT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,dT],null)])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JN],null);$CLJS.X(nM,dM($CLJS.zF,$CLJS.H(["s",TS,"start",vka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,bT],null)])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.X(rQ,dM($CLJS.Ox,$CLJS.H(["s",TS])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);$CLJS.X(PM,dM($CLJS.wG,$CLJS.H(["s",TS])));
var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.X(TM,dM($CLJS.FG,$CLJS.H(["s",TS])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);$CLJS.X(lN,dM($CLJS.yG,$CLJS.H(["s",TS])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lN],null);$CLJS.X(XN,dM($CLJS.Dt,$CLJS.H(["s",TS])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XN],null);$CLJS.X(GM,dM($CLJS.Jt,$CLJS.H(["s",TS])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GM],null);
$CLJS.X(nR,dM($CLJS.EG,$CLJS.H(["s",TS,"match",$CLJS.Zj,"replacement",$CLJS.Zj])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null);$CLJS.X(kS,dM($CLJS.GF,$CLJS.H(["a",TS,"b",TS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,TS],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kS],null);$CLJS.X(MO,dM($CLJS.iG,$CLJS.H(["s",TS,"pattern",$CLJS.Zj])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MO],null);
$CLJS.X(JR,dM($CLJS.Wr,$CLJS.H(["x",eT,"y",eT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,eT],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);$CLJS.X(HR,dM($CLJS.jt,$CLJS.H(["x",bT,"y",eT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,eT],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(GR,dM($CLJS.BG,$CLJS.H(["x",bT,"y",bT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,bT],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);
$CLJS.X(IR,dM($CLJS.Xr,$CLJS.H(["x",bT,"y",bT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,bT],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IR],null);$CLJS.X(YP,dM($CLJS.eG,$CLJS.H(["x",bT])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YP],null);$CLJS.X(RQ,dM($CLJS.CF,$CLJS.H(["x",bT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);$CLJS.X(nS,dM($CLJS.lG,$CLJS.H(["x",bT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nS],null);$CLJS.X(cP,dM($CLJS.HF,$CLJS.H(["x",bT])));
var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);$CLJS.X(iP,dM($CLJS.EF,$CLJS.H(["x",bT,"y",bT])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null);$CLJS.X(FP,dM($CLJS.UF,$CLJS.H(["x",bT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FP],null);$CLJS.X(CM,dM($CLJS.yF,$CLJS.H(["x",bT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);$CLJS.X(dQ,dM($CLJS.DG,$CLJS.H(["x",bT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dQ],null);
$CLJS.X(bN,dM($CLJS.XI,$CLJS.H(["datetime-x",cT,"datetime-y",cT,"unit",ska])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.X(tN,dM($CLJS.lJ,$CLJS.H(["datetime",cT,"unit",rka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,DS],null)])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);$CLJS.X(kP,dM($CLJS.TI,$CLJS.H(["date",cT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kP],null);$CLJS.X(MP,dM($CLJS.pJ,$CLJS.H(["date",cT])));
var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);$CLJS.X(pM,dM($CLJS.MI,$CLJS.H(["date",cT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);$CLJS.X(lS,dM($CLJS.fJ,$CLJS.H(["date",cT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,DS],null)])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lS],null);$CLJS.X(xO,dM($CLJS.eJ,$CLJS.H(["date",cT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xO],null);$CLJS.X(GP,dM($CLJS.jJ,$CLJS.H(["date",cT])));
var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);$CLJS.X(YM,dM($CLJS.mJ,$CLJS.H(["datetime",cT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.X(CO,dM($CLJS.iJ,$CLJS.H(["datetime",cT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CO],null);$CLJS.X(RM,dM($CLJS.SI,$CLJS.H(["datetime",cT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.X(hO,dM($CLJS.OI,$CLJS.H(["datetime",cT,"to",CS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,CS],null)])));
var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null),OT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"datetime arithmetic unit"],null),$CLJS.WG,$CLJS.TG,$CLJS.Ti,$CLJS.qk,$CLJS.Yt,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null);$CLJS.X(mM,dM($CLJS.hJ,$CLJS.H(["datetime",cT,"amount",bT,"unit",OT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null);
$CLJS.X(KM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return dM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.bJ));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null);$CLJS.X(lM,dM($CLJS.nJ,$CLJS.H(["datetime",cT,"amount",bT,"unit",OT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);
$CLJS.X(DQ,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GO,"metabase/mbql/schema.cljc",51,$CLJS.Wr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Wr,$CLJS.yE,$CLJS.U($CLJS.Qj,WP),tR,$CLJS.U($CLJS.Qj,WP),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,WP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:GO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},
$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[aO,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",67,$CLJS.hJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hJ,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS),NQ,$CLJS.U($CLJS.Qj,
SQ),$CLJS.ri,$CLJS.U($CLJS.Qj,$Q)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:NP}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,
$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[aO,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eS,"metabase/mbql/schema.cljc",72,$CLJS.nJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nJ,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS),NQ,$CLJS.U($CLJS.Qj,SQ),$CLJS.ri,$CLJS.U($CLJS.Qj,$Q)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(RT)?RT.H:null])):null));
return $CLJS.n(a)?a:eS}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.OI,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HN,"metabase/mbql/schema.cljc",71,$CLJS.OI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OI,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS),Mia,$CLJS.U($CLJS.Qj,wR),Rha,$CLJS.U($CLJS.Qj,$CLJS.U(iR,wR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:HN}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&
"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",
45,$CLJS.bJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(QT)?QT.H:null])):null));return $CLJS.n(a)?a:lP}(),QT],null)])));$CLJS.ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);$CLJS.X(rM,dM($CLJS.xs,$CLJS.H(["first-clause",$CLJS.ST,"second-clause",$CLJS.ST,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.ST],null)])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);
$CLJS.X(dO,dM($CLJS.rs,$CLJS.H(["first-clause",$CLJS.ST,"second-clause",$CLJS.ST,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,$CLJS.ST],null)])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.X(MM,dM($CLJS.ts,$CLJS.H(["clause",$CLJS.ST])));
var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null),WT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,3,[$CLJS.qt,":field or :expression reference or :relative-datetime",$CLJS.st,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Ni,function(a){return $CLJS.n(cM($CLJS.$I,a))?$CLJS.$I:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$CLJS.OS],null)],null);
$CLJS.X(AQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.qt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.rs,$CLJS.Gs,$CLJS.Bl,$CLJS.Zj,JS,WT,dT,KS],null)],null));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AQ],null);
$CLJS.X(RO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"order comparable",$CLJS.Ni,function(a){return $CLJS.n(cM($CLJS.Dj,a))?$CLJS.Dj:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dj,KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.rs,$CLJS.Bl,$CLJS.Zj,JS,dT,WT],null)],null)],null));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RO],null);
$CLJS.X(zR,dM($CLJS.Ur,$CLJS.H(["field",XT,"value-or-field",XT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,XT],null)])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zR],null);$CLJS.X(DR,dM($CLJS.xG,$CLJS.H(["field",XT,"value-or-field",XT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Oi,XT],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(yR,dM($CLJS.Rr,$CLJS.H(["field",YT,"value-or-field",YT])));
var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yR],null);$CLJS.X(xR,dM($CLJS.Nr,$CLJS.H(["field",YT,"value-or-field",YT])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xR],null);$CLJS.X(mN,dM($CLJS.Tr,$CLJS.H(["field",YT,"value-or-field",YT])));var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.X(sN,dM($CLJS.Pr,$CLJS.H(["field",YT,"value-or-field",YT])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null);$CLJS.X(hQ,dM($CLJS.JF,$CLJS.H(["field",YT,"min",YT,"max",YT])));
var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null);$CLJS.X(KQ,dM($CLJS.PF,$CLJS.H(["lat-field",YT,"lon-field",YT,"lat-max",YT,"lon-min",YT,"lat-min",YT,"lon-max",YT])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);$CLJS.X(WN,dM($CLJS.IF,$CLJS.H(["field",$CLJS.OS])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WN],null);$CLJS.X(LO,dM($CLJS.TF,$CLJS.H(["field",$CLJS.OS])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LO],null);$CLJS.X(nO,dM($CLJS.KF,$CLJS.H(["field",$CLJS.OS])));
var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nO],null);$CLJS.X(uM,dM($CLJS.RF,$CLJS.H(["field",$CLJS.OS])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uM],null),kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null);$CLJS.X(AO,dM($CLJS.YF,$CLJS.H(["field",TS,"string-or-field",TS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,kU],null)])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);
$CLJS.X(tP,dM($CLJS.rG,$CLJS.H(["field",TS,"string-or-field",TS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,kU],null)])));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tP],null);$CLJS.X(lO,dM($CLJS.fG,$CLJS.H(["field",TS,"string-or-field",TS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,kU],null)])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(bQ,dM($CLJS.ZF,$CLJS.H(["field",TS,"string-or-field",TS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,kU],null)])));
var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);$CLJS.X(DM,dM($CLJS.sG,$CLJS.H(["field",$CLJS.OS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.os,$CLJS.Sv,$CLJS.Ux,$CLJS.OF],null)],null),"unit",ES,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null)],null)])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);
$CLJS.X(tQ,dM($CLJS.FF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PK],null),pS],null)])));var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);
$CLJS.X(BN,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(mR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VO,"metabase/mbql/schema.cljc",15,$CLJS.xs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,LN,$CLJS.U($CLJS.Qj,$O),DP,$CLJS.U($CLJS.Qj,$O),VN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,$O))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:VO}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},
$CLJS.od(HP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",14,$CLJS.rs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,LN,$CLJS.U($CLJS.Qj,$O),DP,$CLJS.U($CLJS.Qj,$O),VN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,$O))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:KP}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(uQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",15,$CLJS.ts,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.aS,$CLJS.U($CLJS.Qj,$O)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:mP}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==
typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",13,$CLJS.Rr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:OO}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(TR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",14,$CLJS.Tr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:NO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&
"undefined"!==typeof iM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(OP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PO,"metabase/mbql/schema.cljc",13,$CLJS.Nr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nr,$CLJS.gG,$CLJS.U($CLJS.Qj,
NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:PO}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(MR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],
[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",14,$CLJS.Pr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Pr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:WO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&
"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Ur,$CLJS.gG,$CLJS.U($CLJS.Qj,ZR),YQ,$CLJS.U($CLJS.Qj,ZR),BQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,ZR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:QO}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(cN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,
$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yN,"metabase/mbql/schema.cljc",14,$CLJS.xG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xG,$CLJS.gG,$CLJS.U($CLJS.Qj,ZR),YQ,$CLJS.U($CLJS.Qj,ZR),BQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,ZR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n($T)?$T.H:null])):null));
return $CLJS.n(a)?a:yN}(),$T],null)])));
$CLJS.X(IP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"valid filter expression",$CLJS.Ni,function(a){return $CLJS.n(cM(XS,a))?$CLJS.aJ:$CLJS.n(cM(US,a))?hS:$CLJS.n(cM(RS,a))?$CLJS.Zj:$CLJS.n(cM(VS,a))?$CLJS.Gs:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[hS,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,eM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(mR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",
15,$CLJS.xs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,LN,$CLJS.U($CLJS.Qj,$O),DP,$CLJS.U($CLJS.Qj,$O),VN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,$O))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:VO}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.od(HP,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",14,$CLJS.rs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,LN,$CLJS.U($CLJS.Qj,$O),DP,$CLJS.U($CLJS.Qj,$O),VN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,$O))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(UT)?
UT.H:null])):null));return $CLJS.n(a)?a:KP}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.od(uQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),mP,"metabase/mbql/schema.cljc",15,$CLJS.ts,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,$CLJS.aS,$CLJS.U($CLJS.Qj,$O)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:mP}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(PP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",13,$CLJS.Ur,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ur,$CLJS.gG,$CLJS.U($CLJS.Qj,ZR),YQ,$CLJS.U($CLJS.Qj,ZR),BQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,ZR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(ZT)?ZT.H:null])):
null));return $CLJS.n(a)?a:QO}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(cN,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yN,"metabase/mbql/schema.cljc",14,$CLJS.xG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xG,$CLJS.gG,$CLJS.U($CLJS.Qj,ZR),YQ,$CLJS.U($CLJS.Qj,ZR),BQ,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,ZR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:yN}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},
$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",13,$CLJS.Rr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:OO}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(OP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),PO,"metabase/mbql/schema.cljc",13,$CLJS.Nr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Nr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:PO}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(TR,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",14,$CLJS.Tr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(cU)?cU.H:null])):
null));return $CLJS.n(a)?a:NO}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(MR,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WO,"metabase/mbql/schema.cljc",14,$CLJS.Pr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Pr,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),YQ,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:WO}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(lja,new $CLJS.h(null,
1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eN,"metabase/mbql/schema.cljc",19,$CLJS.JF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JF,$CLJS.gG,$CLJS.U($CLJS.Qj,NN),$CLJS.Zn,$CLJS.U($CLJS.Qj,NN),$CLJS.jk,$CLJS.U($CLJS.Qj,NN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:eN}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cR,"metabase/mbql/schema.cljc",23,$CLJS.YF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YF,$CLJS.gG,$CLJS.U($CLJS.Qj,XQ),xN,$CLJS.U($CLJS.Qj,XQ),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,sQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:cR}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},
$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",21,$CLJS.rG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rG,$CLJS.gG,$CLJS.U($CLJS.Qj,XQ),xN,$CLJS.U($CLJS.Qj,XQ),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,sQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:CN}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(Bia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",20,$CLJS.fG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fG,$CLJS.gG,$CLJS.U($CLJS.Qj,XQ),xN,$CLJS.U($CLJS.Qj,XQ),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,sQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:ON}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==
typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(fka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sM,"metabase/mbql/schema.cljc",36,$CLJS.ZF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.ZF,$CLJS.gG,$CLJS.U($CLJS.Qj,XQ),xN,$CLJS.U($CLJS.Qj,XQ),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,sQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:sM}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",26,$CLJS.PF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.PF,Xha,$CLJS.U($CLJS.Qj,NN),kka,$CLJS.U($CLJS.Qj,NN),Wja,$CLJS.U($CLJS.Qj,NN),$ha,$CLJS.U($CLJS.Qj,NN),Qja,$CLJS.U($CLJS.Qj,NN),Wha,$CLJS.U($CLJS.Qj,
NN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:aR}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vP,"metabase/mbql/schema.cljc",28,$CLJS.KF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,$CLJS.gG,$CLJS.U($CLJS.Qj,GN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:vP}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&
"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iQ,"metabase/mbql/schema.cljc",29,$CLJS.RF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RF,$CLJS.gG,$CLJS.U($CLJS.Qj,GN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:iQ}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",27,$CLJS.IF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.gG,$CLJS.U($CLJS.Qj,GN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:UN}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&
"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jN,"metabase/mbql/schema.cljc",28,$CLJS.TF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.gG,$CLJS.U($CLJS.Qj,GN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:jN}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IQ,"metabase/mbql/schema.cljc",33,$CLJS.sG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.sG,$CLJS.gG,$CLJS.U($CLJS.Qj,GN),$CLJS.rk,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.os,$CLJS.Sv,$CLJS.Ux,$CLJS.OF],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Qj,UO),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,qja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:IQ}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),KR,"metabase/mbql/schema.cljc",27,$CLJS.FF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,dia,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,Gja,WM],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:KR}(),qU],null)]))],null)],null));
$CLJS.X(vQ,dM($CLJS.QF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.h(null,1,[$CLJS.qt,":case subclause"],null),$CLJS.ST,dT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),dT],null)],null)],null)])));
var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);
$CLJS.X(bR,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof qT?new $CLJS.Gc(function(){return qT},$CLJS.od(SP,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),GO,"metabase/mbql/schema.cljc",51,$CLJS.Wr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Wr,$CLJS.yE,$CLJS.U($CLJS.Qj,WP),tR,$CLJS.U($CLJS.Qj,WP),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,WP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:GO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},
$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),HO,"metabase/mbql/schema.cljc",51,$CLJS.jt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jt,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ),
tR,$CLJS.U($CLJS.Qj,WP),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,WP))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:HO}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM?new $CLJS.Gc(function(){return sT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,jP,$CLJS.Si,$CLJS.nj,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cA,"metabase/mbql/schema.cljc",$CLJS.BG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BG,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ),tR,$CLJS.U($CLJS.Qj,SQ),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,SQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.cA}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FO,"metabase/mbql/schema.cljc",51,$CLJS.Xr,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Xr,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ),tR,$CLJS.U($CLJS.Qj,SQ),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,SQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:FO}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof fT?new $CLJS.Gc(function(){return fT},
$CLJS.od(FQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",58,$CLJS.jG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jG,CR,$CLJS.U($CLJS.Qj,xM),BR,$CLJS.U($CLJS.Qj,
xM),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,xM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:UQ}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},$CLJS.od(zja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,
$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",56,$CLJS.Ox,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ox,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:lQ}(),hT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),gP,"metabase/mbql/schema.cljc",55,$CLJS.eG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:gP}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(jia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VM,"metabase/mbql/schema.cljc",54,$CLJS.CF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(vT)?
vT.H:null])):null));return $CLJS.n(a)?a:VM}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",55,$CLJS.lG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:XO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&
"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(gka,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zP,"metabase/mbql/schema.cljc",53,$CLJS.HF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.HF,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:zP}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,
fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[QR,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lR,"metabase/mbql/schema.cljc",69,$CLJS.EF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.EF,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ),tR,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:lR}(),yT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[QR,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),BO,"metabase/mbql/schema.cljc",68,$CLJS.UF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:BO}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),
$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[QR,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",67,$CLJS.yF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(AT)?
AT.H:null])):null));return $CLJS.n(a)?a:ZO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[QR,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VR,"metabase/mbql/schema.cljc",67,$CLJS.DG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,$CLJS.yE,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:VR}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&
"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(IM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",61,$CLJS.QF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.QF,$CLJS.ax,$CLJS.U($CLJS.Qj,$N),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,JP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:qP}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.XI,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",65,$CLJS.XI,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XI,sia,$CLJS.U($CLJS.Qj,gS),tia,$CLJS.U($CLJS.Qj,gS),$CLJS.ri,$CLJS.U($CLJS.Qj,Zia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:AP}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TP,"metabase/mbql/schema.cljc",71,$CLJS.lJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lJ,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS),$CLJS.ri,$CLJS.U($CLJS.Qj,$ja),$CLJS.xi,$CLJS.U($CLJS.Qj,$CLJS.U(iR,oS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:TP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),rN,"metabase/mbql/schema.cljc",71,$CLJS.TI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.CP,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:rN}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",74,$CLJS.pJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,$CLJS.CP,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:RP}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(fja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),
$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nQ,"metabase/mbql/schema.cljc",72,$CLJS.MI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MI,$CLJS.CP,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:nQ}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==
typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",
71,$CLJS.fJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fJ,$CLJS.CP,$CLJS.U($CLJS.Qj,gS),$CLJS.xi,$CLJS.U($CLJS.Qj,$CLJS.U(iR,oS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:mQ}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.bk,
!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fQ,"metabase/mbql/schema.cljc",70,$CLJS.eJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eJ,$CLJS.CP,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:fQ}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),
$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XP,"metabase/mbql/schema.cljc",78,$CLJS.jJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jJ,$CLJS.CP,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:XP}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==
typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yM,"metabase/mbql/schema.cljc",
71,$CLJS.mJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mJ,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:yM}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$R,"metabase/mbql/schema.cljc",73,$CLJS.iJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(LT)?LT.H:null])):null));
return $CLJS.n(a)?a:$R}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,
[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sP,"metabase/mbql/schema.cljc",73,$CLJS.SI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,$CLJS.aJ,$CLJS.U($CLJS.Qj,gS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:sP}(),MT],null)])));
$CLJS.X(NM,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof gT?new $CLJS.Gc(function(){return gT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wQ,"metabase/mbql/schema.cljc",59,$CLJS.zF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zF,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ),$CLJS.xv,$CLJS.U($CLJS.Qj,via),$CLJS.Ox,$CLJS.U($CLJS.Qj,$CLJS.U(iR,SQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:wQ}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof iT?new $CLJS.Gc(function(){return iT},
$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",54,$CLJS.wG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:TQ}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",55,$CLJS.yG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:CQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&
"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",
55,$CLJS.FG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:EP}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,
$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ER,"metabase/mbql/schema.cljc",57,$CLJS.EG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EG,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ),aia,$CLJS.U($CLJS.Qj,$CLJS.Zj),$CLJS.jA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:ER}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jS,"metabase/mbql/schema.cljc",55,$CLJS.Jt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Jt,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:jS}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==
typeof iM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",55,$CLJS.Dt,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dt,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:fR}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,
$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sO,"metabase/mbql/schema.cljc",56,$CLJS.GF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GF,CR,$CLJS.U($CLJS.Qj,XQ),BR,$CLJS.U($CLJS.Qj,XQ),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,XQ))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:sO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.$P,"null",$CLJS.Vda,"null"],
null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pN,"metabase/mbql/schema.cljc",74,$CLJS.iG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iG,$CLJS.ky,$CLJS.U($CLJS.Qj,XQ),$CLJS.iA,$CLJS.U($CLJS.Qj,$CLJS.Zj)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:pN}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==
typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof fT?new $CLJS.Gc(function(){return fT},$CLJS.od(FQ,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$P,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
UQ,"metabase/mbql/schema.cljc",58,$CLJS.jG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jG,CR,$CLJS.U($CLJS.Qj,xM),BR,$CLJS.U($CLJS.Qj,xM),KN,$CLJS.U($CLJS.Qj,$CLJS.U($CLJS.Li,xM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:UQ}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},
$CLJS.od(IM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",61,$CLJS.QF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QF,$CLJS.ax,$CLJS.U($CLJS.Qj,$N),
$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,JP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:qP}(),rU],null)])));
$CLJS.sU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,":field or :expression reference or expression",$CLJS.Ni,function(a){return $CLJS.n(cM(US,a))?hS:$CLJS.n(cM(RS,a))?$CLJS.Zj:$CLJS.n(cM(VS,a))?$CLJS.Gs:$CLJS.n(cM(XS,a))?$CLJS.aJ:$CLJS.n(cM($CLJS.QF,a))?$CLJS.QF:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[hS,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.$S],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QF,rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$CLJS.OS],null)],null);$CLJS.X(NR,dM($CLJS.Qx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.OS],null)])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NR],null);$CLJS.X(BP,dM($CLJS.hG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,$CLJS.OS],null)])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BP],null);$CLJS.X(LM,dM($CLJS.aG,$CLJS.H(["field-or-expression",$CLJS.sU])));
var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.X(FR,dM($CLJS.CG,$CLJS.H(["field-or-expression",$CLJS.sU])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(kO,dM($CLJS.MF,$CLJS.H(["field-or-expression",$CLJS.sU])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kO],null);$CLJS.X(VQ,dM($CLJS.tG,$CLJS.H(["field-or-expression",$CLJS.sU])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VQ],null);$CLJS.X(fO,dM($CLJS.Zn,$CLJS.H(["field-or-expression",$CLJS.sU])));
var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null);$CLJS.X(SN,dM($CLJS.jk,$CLJS.H(["field-or-expression",$CLJS.sU])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SN],null);$CLJS.X(MN,dM($CLJS.AG,$CLJS.H(["field-or-expression",$CLJS.sU,"pred",$CLJS.ST])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MN],null);$CLJS.X(cO,dM($CLJS.pG,$CLJS.H(["pred",$CLJS.ST])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cO],null);$CLJS.X(MQ,dM($CLJS.nG,$CLJS.H(["pred",$CLJS.ST])));
var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null);$CLJS.X(iN,dM($CLJS.vG,$CLJS.H(["field-or-expression",$CLJS.sU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null);$CLJS.X(kM,dM($CLJS.NF,$CLJS.H(["field-or-expression",$CLJS.sU])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);$CLJS.X(oO,dM($CLJS.uG,$CLJS.H(["field-or-expression",$CLJS.sU])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oO],null);$CLJS.X(bP,dM($CLJS.SF,$CLJS.H(["field-or-expression",$CLJS.sU,"percentile",bT])));
var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bP],null);$CLJS.X(PQ,dM($CLJS.bG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NK],null),pS],null)])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PQ],null);
$CLJS.X(LQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"unnamed aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(cM(US,a))?eP:$CLJS.Ft}],null),new $CLJS.P(null,2,5,$CLJS.Q,[eP,YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(vja,
new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",60,$CLJS.aG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:nP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(Gia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",64,$CLJS.CG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CG,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:sR}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==
typeof iM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rP,"metabase/mbql/schema.cljc",65,$CLJS.MF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MF,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:rP}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,
$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.qG,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",76,$CLJS.vG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:FM}(),EU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aN,"metabase/mbql/schema.cljc",60,$CLJS.tG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:aN}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(Yha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,
$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Xs,"metabase/mbql/schema.cljc",60,$CLJS.Zn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zn,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(zU)?zU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Xs}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.at,"metabase/mbql/schema.cljc",60,$CLJS.jk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:$CLJS.at}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&
"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",18,$CLJS.bG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bG,Hia,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.rs,Lia,WM],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:rO}(),IU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,
$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jO,"metabase/mbql/schema.cljc",62,$CLJS.nG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,$CLJS.ro,$CLJS.U($CLJS.Qj,$O)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:jO}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),XM,"metabase/mbql/schema.cljc",68,$CLJS.pG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,$CLJS.ro,$CLJS.U($CLJS.Qj,$O)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:XM}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(eka,new $CLJS.h(null,1,[$CLJS.bk,!0],
null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",66,$CLJS.AG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.AG,gR,$CLJS.U($CLJS.Qj,ZQ),$CLJS.ro,$CLJS.U($CLJS.Qj,$O)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:oP}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(IM,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,
new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",61,$CLJS.QF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.QF,$CLJS.ax,$CLJS.U($CLJS.Qj,$N),$CLJS.fj,$CLJS.U($CLJS.Qj,$CLJS.U(iR,JP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:qP}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&
"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.cG,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",
68,$CLJS.uG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:qR}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,
jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.cG,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",72,$CLJS.SF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.SF,gR,$CLJS.U($CLJS.Qj,ZQ),$CLJS.SF,$CLJS.U($CLJS.Qj,SQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(HU)?HU.H:null])):
null));return $CLJS.n(a)?a:OQ}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AM,"metabase/mbql/schema.cljc",77,$CLJS.NF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,gR,$CLJS.U($CLJS.Qj,ZQ)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:AM}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(Vha,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,
$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",74,$CLJS.hG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.gG,$CLJS.U($CLJS.Qj,$CLJS.U(iR,GN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:aP}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([hP,$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,kR,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.BF,"null"],null),null),
$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.Qx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.gG,$CLJS.U($CLJS.Qj,$CLJS.U(iR,GN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),tU],null)]))],null)],null));
$CLJS.X(cQ,dM($CLJS.vM,$CLJS.H(["aggregation",LQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),pS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),pS],null)],null)])));
$CLJS.X(hR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.qt,"aggregation clause or numeric expression",$CLJS.Ni,function(a){return $CLJS.n(cM($CLJS.vM,a))?$CLJS.vM:ZN}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[ZN,LQ],null)],null));$CLJS.X(EM,dM($CLJS.dG,$CLJS.H(["field",QS])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);$CLJS.X(dP,dM($CLJS.XF,$CLJS.H(["field",QS])));
KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null);
wka=eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
KO,"metabase/mbql/schema.cljc",15,$CLJS.dG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,$CLJS.gG,$CLJS.U($CLJS.Qj,AR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:KO}(),JU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,
$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",16,$CLJS.XF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,$CLJS.gG,$CLJS.U($CLJS.Qj,AR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:GQ}(),KU],null)]));
LU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.os,$CLJS.HM,$CLJS.wN,$CLJS.AF,$CLJS.St,$CLJS.uB,$CLJS.CP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lE,pS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),pS],null)],null);
xka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,LU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.HM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tM,pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UP,sS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),sS],null)],null)],null);
yka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,LU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.wN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dR,sS],null)],null)],null);
MU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,LU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Hs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null)],null);zka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rR],null);
Aka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,MU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.AF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AF,NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,$CLJS.Xi,$CLJS.Hs],
null)],null)],null)],null)],null);$CLJS.NU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.CP,null,$CLJS.St,null,$CLJS.Gs,null,$CLJS.uB,null],null),null);Bka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),$CLJS.NU);
$CLJS.X(zQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AF,Aka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HM,xka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wN,yka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,MU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,Bka],null)],null)],null)],null)],null));
var OU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,pS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mO,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,pS],null)],null)],null),Cka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,OU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,$CLJS.Hs],null)],null)],null),PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gQ],null),QU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Ni,function(a){return $CLJS.n($CLJS.eE($CLJS.xd,
$CLJS.jQ)(a))?$CLJS.jQ:$CLJS.kE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,OU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jQ,$CLJS.Hs],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kE,PU],null)],null),RU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dD,qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[mja,pS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.EO,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null)],null),SU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,oka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,new $CLJS.h(null,1,[$CLJS.qt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Dka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.fF,null,$CLJS.PE,null,$CLJS.ZE,null,$CLJS.cF,null],null),null)),TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null);
$CLJS.X(iO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),SU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RR,$CLJS.ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),Dka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.qt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.vy,$CLJS.dA],null),TU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),pS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,uS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SO,new $CLJS.h(null,
1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,RU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.eE($CLJS.yA($CLJS.fP,$CLJS.JQ),$CLJS.Te($CLJS.eE($CLJS.fP,$CLJS.JQ)))],null)],null));var Eka=$CLJS.Q,UU;var VU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null)],null);
if($CLJS.wd(VU)&&$CLJS.E.g($CLJS.A(VU),$CLJS.zj)){var WU=$CLJS.y(VU);$CLJS.A(WU);var XU=$CLJS.B(WU),Fka=$CLJS.xd($CLJS.A(XU))?XU:$CLJS.ee(null,XU),YU=$CLJS.y(Fka),Gka=$CLJS.A(YU),Hka=$CLJS.B(YU);UU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.R.j(Gka,$CLJS.Zn,1)],null),Hka)}else UU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,VU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(kN,new $CLJS.P(null,3,5,Eka,[$CLJS.xs,UU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"All join aliases must be unique."],null),function(a){return $CLJS.PL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.UE,a)))}],null)],null));var Ika=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);
$CLJS.X(pR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,1,[$CLJS.qt,"Distinct, non-empty sequence of Field clauses"],null),fM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.OS],null))],null));
$CLJS.X(gQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JQ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),QU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),SU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.aT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,new $CLJS.h(null,1,
[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),$CLJS.OS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$P,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,pS,$CLJS.sU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),TU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mS,new $CLJS.h(null,
1,[$CLJS.Ls,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PR,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),fM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),wka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[kQ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[kQ,sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[nia,sS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),Ika],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SO,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,RU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.em(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JQ,$CLJS.fP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,
new $CLJS.h(null,1,[$CLJS.qt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.VF);b=$CLJS.J.g(b,$CLJS.QE);return $CLJS.td($CLJS.fu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.ZU=$CLJS.Ig([$CLJS.zG,$CLJS.CP,eO,QN,TO,dS,zO,$CLJS.cS,$CLJS.St,yO,OM,WQ,SR,$CLJS.dN,OR,bO,$CLJS.YO,wO,$CLJS.Zi,$CLJS.vO,wM,$CLJS.AN,$CLJS.uO,xP,$CLJS.Gs,$CLJS.uB,qO],[new $CLJS.h(null,1,[JO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.zG,null,$CLJS.CP,null,$CLJS.St,null,$CLJS.Gs,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.zG,null,$CLJS.CP,null,$CLJS.cS,null,$CLJS.YO,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Zj,$CLJS.tE,eR,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[eO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,hS,$CLJS.tE,eR,JO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.zG,null,QN,null,$CLJS.St,null,$CLJS.Zi,null,wM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.tE,SM,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[TO,null],null),null)],null),new $CLJS.h(null,1,[JO,new $CLJS.ah(null,new $CLJS.h(null,1,[dS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
hS,$CLJS.tE,Eja,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[zO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.cS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,hS,JO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.zG,null,QN,null,$CLJS.St,null,$CLJS.Zi,null,wM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,hS,$CLJS.tE,SM,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[yO,null],null),null)],null),new $CLJS.h(null,1,[JO,new $CLJS.ah(null,
new $CLJS.h(null,1,[OM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.tE,eR,JO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.zG,null,dS,null,OM,null,WQ,null,wO,null,$CLJS.Zi,null,wM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.tE,SM,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[SR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.cS,null,$CLJS.dN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.mj,$CLJS.Zj,$CLJS.tE,SM,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[OR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,$CLJS.Zj,$CLJS.tE,SM,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[bO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.zG,null,$CLJS.CP,null,$CLJS.cS,null,$CLJS.YO,null,$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,1,[JO,new $CLJS.ah(null,new $CLJS.h(null,1,[wO,null],null),null)],null),new $CLJS.h(null,1,[JO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.Zi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.cS,null,$CLJS.vO,null],null),null)],null),new $CLJS.h(null,1,[JO,new $CLJS.ah(null,new $CLJS.h(null,1,[wM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.cS,null,$CLJS.AN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.CP,JO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.cS,
null,$CLJS.uO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,hS,$CLJS.tE,eR,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[xP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Gs,JO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.zG,null,$CLJS.Zi,null,$CLJS.Gs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.mj,$CLJS.Zj,JO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.zG,null,dS,null,OM,null,WQ,null,wO,null,$CLJS.Zi,null,wM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.mj,
hS,$CLJS.tE,SM,JO,new $CLJS.ah(null,new $CLJS.h(null,1,[qO,null],null),null)],null)]);$CLJS.X(rR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.ZU)));$CLJS.X($M,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"valid template tag widget type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.ZU)));
$CLJS.X(EQ,dM(eia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,pS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,pS],null)],null)],null)])));var $U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.X(zM,dM($CLJS.AF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.OS,$U],null)])));var aV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);$CLJS.X(uN,dM(tO,$CLJS.H(["target",$U])));
var bV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uN],null),Jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.OS,eM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof aV?new $CLJS.Gc(function(){return aV},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,
1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",21,$CLJS.AF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,$CLJS.sJ,$CLJS.U($CLJS.Qj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,GN,IO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(aV)?aV.H:null])):null));return $CLJS.n(a)?a:oQ}(),aV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=jP.h($CLJS.pd("undefined"!==typeof $CLJS.gM&&
"undefined"!==typeof hM&&"undefined"!==typeof iM&&"undefined"!==typeof bV?new $CLJS.Gc(function(){return bV},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.bk,!0],null)),$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,jP,$CLJS.Si,$CLJS.nj,$CLJS.yk,fN,$CLJS.ti,$CLJS.kk,$CLJS.ok],[$CLJS.od(QQ,new $CLJS.h(null,1,[$CLJS.kk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",20,tO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[tO,
$CLJS.sJ,$CLJS.U($CLJS.Qj,IO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(bV)?bV.H:null])):null));return $CLJS.n(a)?a:iS}(),bV],null)]))],null),$L=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);
$CLJS.X(gN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.eE($CLJS.yA($CLJS.jQ,$CLJS.DF),$CLJS.Te($CLJS.eE($CLJS.jQ,$CLJS.DF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.jQ);var d=$CLJS.J.g(c,$CLJS.DF);c=$CLJS.J.g(c,$CLJS.mj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.qt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,$CLJS.DF,$CLJS.jQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jQ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),Cka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),PU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,zka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),pS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),Jka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Hs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),pS],null),new $CLJS.P(null,3,5,$CLJS.Q,[kia,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),
pS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Hs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[cka,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),CS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bja,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[pP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),tS],null),new $CLJS.P(null,3,5,$CLJS.Q,[ZM,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),tS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,pP);b=$CLJS.J.g(b,ZM);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sia,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[Nja,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[gia,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qha,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[mia,new $CLJS.h(null,1,[$CLJS.Ls,
!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fja,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sha,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tja,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fy,new $CLJS.h(null,
1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.os,rja,oja,$CLJS.mO,qia,mka,tja,Iia,dja,Aia,hia,Eia,Cja,Qia,bia,ria,lka,ika],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ija,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,sS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Nia,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dR,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,nka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dka,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,pS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pia,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,sS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[bka,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,$CLJS.Hs,$CLJS.Hs],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pO,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Vi,$CLJS.Hs,$CLJS.Hs],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LK,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Hs],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.SO)],null)],null));var cV=$CLJS.ZL($L);
(function(){var a=Lha();return function(b){if($CLJS.n(cV.h?cV.h(b):cV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.WL(b);throw $CLJS.hi($CLJS.gE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.By,c,Xia,b],null));}})();