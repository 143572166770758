var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var l7,n7,o7,qra,rra,sra,r7,s7,u7,v7,tra,w7,ura,vra;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.xE,$CLJS.p.h($CLJS.HE()),$CLJS.pB,$CLJS.pB.h(a),$CLJS.Ei,$CLJS.yA($CLJS.Ei,$CLJS.pB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,b,$CLJS.yA($CLJS.pE,$CLJS.T)(a)],null)};$CLJS.m7=function(a){return $CLJS.C5.h(a)};n7=function(a){return $CLJS.R.j(a,$CLJS.Ej,$CLJS.T4)};
o7=function(a,b){return $CLJS.E.g($CLJS.kE.h(a),$CLJS.em(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BE,$CLJS.jE,$CLJS.AE],null)))};qra=function(a){return $CLJS.p1(a,new $CLJS.h(null,1,[$CLJS.BE,$CLJS.zh],null))};
rra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.tH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.field",c))){var d=$CLJS.gE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Rk.g($CLJS.KZ,b)]))]));d instanceof Error?$CLJS.GA("metabase.lib.field",c,$CLJS.zw(),d):$CLJS.GA("metabase.lib.field",
c,$CLJS.zw.l($CLJS.H([d])),null)}return null};
sra=function(a,b,c){if($CLJS.n(p7))return null;var d=p7;p7=!0;try{var e=$CLJS.b2(a,b),f=$CLJS.n(e)?$CLJS.FW(a,e):$CLJS.FW(a,b),k=function(){var m=$CLJS.q7.h(f);if($CLJS.n(m))return m;m=$CLJS.zA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oV,$CLJS.CW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.OV.h(f);if($CLJS.n(u))return u;u=$CLJS.fP.h(f);if($CLJS.n(u))return u;u=$CLJS.$P.h(f);return $CLJS.n(u)?u:$CLJS.QE.h(f)}())?$CLJS.L0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.field",
m))){var t=$CLJS.gE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.GA("metabase.lib.field",m,$CLJS.zw(),t):$CLJS.GA("metabase.lib.field",m,$CLJS.zw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?rra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.C3($CLJS.Hk.l(l,$CLJS.Zi,$CLJS.H([$CLJS.ZK,$CLJS.PW,$CLJS.JW])),null),$CLJS.T,function(){var m=$CLJS.KZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.OK,$CLJS.xZ):l:null}finally{p7=d}};
r7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.uP);var f=$CLJS.I(c,2,null);c=$CLJS.gl.l($CLJS.H([function(){var k=$CLJS.pB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.pB,k],null):null}(),function(){var k=$CLJS.yA($CLJS.Ei,$CLJS.pB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Ei,k],null):null}(),function(){var k=$CLJS.vR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.PW,k],null):null}(),function(){var k=$CLJS.mG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.JW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.g2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.MK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.MK,$CLJS.T,f],null):function(){var k=sra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.MK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.C3(c,d):c};s7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.JW);return $CLJS.n($CLJS.n(b)?$CLJS.Id($CLJS.qH,b):b)?$CLJS.wj:$CLJS.yA($CLJS.Ei,$CLJS.pB)(a)};
$CLJS.t7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.xE),l=$CLJS.J.g(f,$CLJS.pB),m=$CLJS.J.g(f,$CLJS.vR),t=$CLJS.J.g(f,$CLJS.Ei);e=$CLJS.J.g(f,$CLJS.uP);var u=$CLJS.J.g(f,$CLJS.FN),v=$CLJS.J.g(f,$CLJS.mG),x=$CLJS.gl.l;k=new $CLJS.h(null,2,[$CLJS.Ej,$CLJS.MK,$CLJS.d1,k],null);f=$CLJS.lE.h(f);a=$CLJS.n(f)?f:$CLJS.a0.j(a,b,d);c=x.call($CLJS.gl,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.lE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Ei,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.pB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.JW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.PW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.YK,u):m;return $CLJS.n(e)?$CLJS.C3(u,e):u};u7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.ri.h(d),c)},a))?$CLJS.Rk.g(function(d){var e=$CLJS.Id(d,b)?$CLJS.Hk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ri.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
v7=function(a){var b=$CLJS.n($CLJS.L1.h(a))?null:function(){var d=$CLJS.OK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.OZ,null,$CLJS.UZ,null,$CLJS.xZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.gl.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.xE,$CLJS.p.h($CLJS.HE()),$CLJS.pB,$CLJS.pB.h(a),$CLJS.Ei,s7(a)],null),function(){var d=$CLJS.E1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.uP,d],null):null}(),function(){var d=$CLJS.JW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.mG,d],
null):null}(),function(){var d=$CLJS.NW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.NW,d],null):null}(),function(){var d=$CLJS.PW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.vR,d],null):null}(),function(){var d=$CLJS.YK.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.FN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.yA($CLJS.KZ,$CLJS.T):$CLJS.yA($CLJS.Zi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,c,b],null)};
tra=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.OK.h(c),$CLJS.EZ)},$CLJS.L0.v(a,b,$CLJS.FW(a,b),new $CLJS.h(null,3,[$CLJS.t0,!1,$CLJS.z0,!0,$CLJS.p0,!1],null)))};w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.q7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);ura=new $CLJS.M(null,"earliest","earliest",-1928154382);vra=new $CLJS.M(null,"latest","latest",24323665);var yra;$CLJS.q1.m(null,$CLJS.gG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.p1(c,new $CLJS.h(null,2,[$CLJS.mG,$CLJS.zh,$CLJS.vR,qra],null)),a],null)});var p7=!1,x7=function x7(a,b){var d=$CLJS.w1(a,w7.h(b));a=$CLJS.n(w7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Sk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.E0.m(null,$CLJS.MK,function(a,b,c){return s7(c)});$CLJS.E0.m(null,$CLJS.gG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.mG);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.JW,d):c;return $CLJS.F0.j(a,b,c)});$CLJS.G0.m(null,$CLJS.MK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.G0.m(null,$CLJS.gG,function(a,b,c){var d=r7(a,b,c);b=$CLJS.t7(a,b,d,c);return $CLJS.n(w7.h(b))?x7(a,b):b});
$CLJS.A0.m(null,$CLJS.MK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.lE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ri),l=$CLJS.J.g(c,$CLJS.PW),m=$CLJS.J.g(c,$CLJS.XZ),t=$CLJS.J.g(c,$CLJS.YK),u=$CLJS.J.g(c,$CLJS.ZK);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.C1.g($CLJS.B1,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.b0)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.w1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.CA($CLJS.lE.h($CLJS.d0.j(a,b,t)),$CLJS.RW,"")):(u=$CLJS.D1(a,u),a=$CLJS.a0.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.E1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.ZA($CLJS.CA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.z5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.A0.m(null,$CLJS.gG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.vR);var k=$CLJS.J.g(f,$CLJS.uP),l=$CLJS.J.g(f,$CLJS.mG);f=$CLJS.J.g(f,$CLJS.FN);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.XZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ri,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.PW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.YK,f):e;return $CLJS.n(e)?$CLJS.a0.v(a,b,e,d):$CLJS.IE("[Unknown Field]")});
$CLJS.B0.m(null,$CLJS.MK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.B0.m(null,$CLJS.gG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=r7(a,b,c);return $CLJS.n(c)?$CLJS.C0.j(a,b,c):"unknown_field"});
$CLJS.H0.m(null,$CLJS.MK,function(a,b,c){return $CLJS.gl.l($CLJS.H([function(){var d=$CLJS.mI($CLJS.H0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.OK.h(c),$CLJS.OZ)?function(){var d=$CLJS.WZ.h(c);return $CLJS.n(d)?(d=$CLJS.X_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.OW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.lE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.V0.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.mG.h(b)});
$CLJS.V0.m(null,$CLJS.MK,function(a){return $CLJS.JW.h(a)});
$CLJS.U0.m(null,$CLJS.gG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Id($CLJS.qH,b),e=$CLJS.oC($CLJS.NW,$CLJS.Ei,$CLJS.pB)(c);c=$CLJS.R.l(c,$CLJS.mG,b,$CLJS.H([$CLJS.Ei,d?$CLJS.wj:e,$CLJS.NW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,c,a],null)}b=$CLJS.NW.h(c);c=$CLJS.n(b)?$CLJS.Hk.g($CLJS.R.j(c,$CLJS.Ei,b),$CLJS.NW):c;c=$CLJS.Hk.g(c,$CLJS.mG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,c,a],null)});
$CLJS.U0.m(null,$CLJS.MK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.JW,b,$CLJS.H([$CLJS.NW,$CLJS.oC($CLJS.NW,$CLJS.Ei,$CLJS.pB)(a)])):$CLJS.Hk.l(a,$CLJS.JW,$CLJS.H([$CLJS.NW]))});$CLJS.X0.m(null,$CLJS.gG,function(a,b,c){return $CLJS.Y0.j(a,b,r7(a,b,c))});
$CLJS.X0.m(null,$CLJS.MK,function(a,b,c){if($CLJS.Nk.g($CLJS.OK.h(c),$CLJS.EZ)){a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(c);b=null==c?null:$CLJS.TN.h(c);if(null==b)var d=null;else try{var e=$CLJS.qD.h($CLJS.mj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,ura),l=$CLJS.J.g(f,vra),m=$CLJS.Fna.l($CLJS.H([$CLJS.S0.h(k),$CLJS.S0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Or.g?$CLJS.Or.g(1,m):$CLJS.Or.call(null,1,m))?$CLJS.Ti:$CLJS.n($CLJS.Or.g?$CLJS.Or.g(31,m):$CLJS.Or.call(null,31,m))?$CLJS.Yt:$CLJS.n($CLJS.Or.g?
$CLJS.Or.g(365,m):$CLJS.Or.call(null,365,m))?$CLJS.oj:$CLJS.xj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.qC(a,$CLJS.qD)?$CLJS.Nna:$CLJS.qC(a,$CLJS.RC)?$CLJS.Mna:$CLJS.qC(a,$CLJS.QD)?$CLJS.Lna:$CLJS.Cf;d=$CLJS.n(d)?u7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.JW.h(c))?u7(d,$CLJS.i0,$CLJS.JW.h(c)):d}return $CLJS.Cf});
$CLJS.C5.m(null,$CLJS.gG,function(a){var b=null==a?null:$CLJS.EE(a);b=null==b?null:$CLJS.vR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Ej,$CLJS.B5,$CLJS.H([$CLJS.A5,function(c,d){return r7(c,d,a)}]))});$CLJS.C5.m(null,$CLJS.MK,function(a){var b=null==a?null:$CLJS.PW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Ej,$CLJS.B5,$CLJS.H([$CLJS.A5,$CLJS.Ue(a)]))});$CLJS.w5.m(null,$CLJS.gG,function(a,b){return $CLJS.FE(a,$CLJS.DE,$CLJS.H([$CLJS.vR,b]))});
$CLJS.w5.m(null,$CLJS.MK,function(a,b){return $CLJS.DE(a,$CLJS.PW,b)});$CLJS.D5.m(null,$CLJS.gG,function(a,b,c){return $CLJS.E5.j(a,b,r7(a,b,c))});
$CLJS.D5.m(null,$CLJS.MK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Ei);var d=$CLJS.J.g(b,$CLJS.TN),e=$CLJS.J.g(b,$CLJS.Vj);if($CLJS.Nk.g($CLJS.OK.h(b),$CLJS.EZ)){var f=function(){var m=null==a?null:$CLJS.u1($CLJS.U_(a));m=null==m?null:$CLJS.eA.h(m);return null==m?null:$CLJS.Id(m,$CLJS.vR)}(),k=$CLJS.zA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,$CLJS.Pj],null)),l=$CLJS.m7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=o7(S,l)?$CLJS.R.j(Z,$CLJS.i0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return o7(G,l)?$CLJS.R.j(K,$CLJS.i0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.Zn.h(k);return $CLJS.n(t)?$CLJS.jk.h(k):t}return f}())?null:$CLJS.qC(e,$CLJS.yj)?$CLJS.Rk.g(n7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.y5(),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("Bin every 0.1 degrees"),$CLJS.kE,new $CLJS.h(null,2,[$CLJS.BE,$CLJS.AE,$CLJS.AE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("Bin every 1 degree"),$CLJS.kE,new $CLJS.h(null,2,[$CLJS.BE,$CLJS.AE,$CLJS.AE,1],null)],null),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("Bin every 10 degrees"),$CLJS.kE,new $CLJS.h(null,2,[$CLJS.BE,$CLJS.AE,$CLJS.AE,10],null)],null),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("Bin every 20 degrees"),
$CLJS.kE,new $CLJS.h(null,2,[$CLJS.BE,$CLJS.AE,$CLJS.AE,20],null)],null)],null)):$CLJS.qC(c,$CLJS.Pj)&&!$CLJS.qC(e,$CLJS.mi)?$CLJS.Rk.g(n7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.y5(),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("10 bins"),$CLJS.kE,new $CLJS.h(null,2,[$CLJS.BE,$CLJS.jE,$CLJS.jE,10],null)],null),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("50 bins"),$CLJS.kE,new $CLJS.h(null,2,[$CLJS.BE,$CLJS.jE,$CLJS.jE,50],null)],null),new $CLJS.h(null,2,[$CLJS.lE,$CLJS.IE("100 bins"),$CLJS.kE,new $CLJS.h(null,2,
[$CLJS.BE,$CLJS.jE,$CLJS.jE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.oW.m(null,$CLJS.gG,function(a){return a});
$CLJS.oW.m(null,$CLJS.MK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.OK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.xE,$CLJS.p.h($CLJS.HE()),$CLJS.Ei,$CLJS.yA($CLJS.Ei,$CLJS.pB)(a)],null),a=$CLJS.d1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.pE.h(a))?l7(a):v7(a);default:return v7(a)}});
$CLJS.wra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Rk.g($CLJS.qW,k));var l=tra(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Xl(function(t){return $CLJS.m2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Qk.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.qW),m):null;return $CLJS.QW.l(e,f,$CLJS.DE,$CLJS.H([$CLJS.QE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.QE.h($CLJS.FW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.xra=function(){function a(d,e){var f=$CLJS.L0.v(d,e,$CLJS.FW(d,e),new $CLJS.h(null,3,[$CLJS.t0,!1,$CLJS.z0,!1,$CLJS.p0,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.td(k)?$CLJS.Rk.g(function(l){return $CLJS.R.j(l,$CLJS.MZ,!0)},f):$CLJS.A3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
yra=function(){function a(d,e,f){var k=$CLJS.FW(d,e),l=$CLJS.E.g($CLJS.cC(f),$CLJS.WF)?$CLJS.J0:$CLJS.L0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.m2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.zra=function(){function a(d,e,f){f=$CLJS.tZ.j(d,e,f);return yra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();