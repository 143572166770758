var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var dV,eV,fV,hV,iV,jV,kV,lV,mV,nV;dV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);eV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);fV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.gV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);hV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);iV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);jV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
kV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);lV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);mV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);nV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(dV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid template tag :widget-type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.ZU)));$CLJS.X(hV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.os,$CLJS.HM,$CLJS.wN,$CLJS.AF,$CLJS.St,$CLJS.uB,$CLJS.CP],null));
$CLJS.X(kV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lE,$CLJS.qE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.qE,$CLJS.Fs],null)],null)],null));
$CLJS.X(lV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Dl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YN,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null)],null));
$CLJS.X(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.AF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fj,new $CLJS.h(null,1,[$CLJS.Ls,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null)],null)],null));
$CLJS.X(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.HM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tM,$CLJS.qE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.dL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VP,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.XK],null)],null)],null));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.wN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dR,$CLJS.WK],null)],null)],null));$CLJS.X(mV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),$CLJS.NU));
$CLJS.X(eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null)],null)],null));
$CLJS.X(fV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Ni,$CLJS.mj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$o,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eV],null)],null)],null)],null));
$CLJS.X($CLJS.gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,$CLJS.qE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));