var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AL,CL,EL,HL,ML;$CLJS.vL=function(a){return $CLJS.Wh($CLJS.q($CLJS.eC),a,$CLJS.kG)};$CLJS.wL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.xL=new $CLJS.M(null,"row","row",-570139521);$CLJS.yL=new $CLJS.M(null,"column-ref","column-ref",2018188376);$CLJS.zL=new $CLJS.M(null,"object-id","object-id",-754527291);AL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);
$CLJS.BL=new $CLJS.M(null,"operators","operators",-2064102509);CL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.DL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);EL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.FL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.GL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
HL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.IL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.JL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.KL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.LL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
ML=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.NL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.OL=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);$CLJS.X(CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.vE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.cH],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,CL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.zE],null)],null)],null));$CLJS.X(EL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.gG],null),ML,$CLJS.qE],null));
$CLJS.X(HL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.gG],null),CL,$CLJS.eL],null));
$CLJS.GG.g($CLJS.gG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.gG],null),CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.eL,$CLJS.qE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.Ni,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.cC(a)):null},$CLJS.qt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.hB,HL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gB,EL],null)],null)],null));$CLJS.oF($CLJS.gG,$CLJS.kG);$CLJS.KE.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.XE});$CLJS.vF($CLJS.mB,$CLJS.H([$CLJS.qE]));$CLJS.KE.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.XE});
$CLJS.oF($CLJS.mB,$CLJS.kG);$CLJS.X(AL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.vE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.qE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.qE],null)],null)],null));$CLJS.GG.g($CLJS.WF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.WF],null),AL,$CLJS.Zj],null));
$CLJS.KE.m(null,$CLJS.WF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Ei,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.XE});$CLJS.oF($CLJS.WF,$CLJS.kG);$CLJS.vF($CLJS.FF,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PK],null)],null)]));$CLJS.oF($CLJS.FF,$CLJS.kG);
$CLJS.vF($CLJS.bG,$CLJS.H([$CLJS.jt,$CLJS.XE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NK],null)],null)]));$CLJS.oF($CLJS.bG,$CLJS.kG);
$CLJS.X($CLJS.kG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.ME,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.st,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Bt(", ",$CLJS.SG($CLJS.Jd,$CLJS.kl.g($CLJS.q($CLJS.eC),$CLJS.kG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.vL(a)}],null)],null));