var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var F5,Rqa,Sqa,Tqa,H5,I5,J5,Uqa,Vqa,M5,K5,L5,N5,O5,Wqa,Xqa,Yqa,P5,Zqa,$qa,Q5,ara,bra;F5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=F5($CLJS.J.g(a,b),c),$CLJS.td(c)?$CLJS.Hk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Hk.g(a,b)}return a};
Rqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.A(u);u=$CLJS.B(u);var v=$CLJS.fm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Fb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Se.j(l,$CLJS.Fb(v),m)):f}(a,b,c,d)};Sqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return Rqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.G5=function(a){return $CLJS.xE.h($CLJS.EE(a))};
Tqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.$P,$CLJS.A(b))?$CLJS.zW(d,$CLJS.yW(c)):d;return Sqa(a,b,function(f){return $CLJS.Df(function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z);C=$CLJS.E.g($CLJS.G5(C),$CLJS.G5(c))?e:C;x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);return $CLJS.ee($CLJS.E.g($CLJS.G5(x),
$CLJS.G5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
H5=function(a,b,c,d){var e=$CLJS.zA(a,b);if($CLJS.n(e)){var f=$CLJS.G5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),$CLJS.id(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.h($CLJS.Pk.g($CLJS.ch([f]),$CLJS.G5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QE],null))&&$CLJS.Qe(function(l){return $CLJS.GY(l,$CLJS.mB)},e)?null:e;if($CLJS.y(e))return $CLJS.XL(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EN,$CLJS.qL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.hi($CLJS.IE("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.By,$CLJS.KW,$CLJS.qL,$CLJS.zA(a,b),$CLJS.LW,$CLJS.zA(a,$CLJS.sd(b)),$CLJS.DL,d,$CLJS.MW,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EN,$CLJS.QE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.vX.v(a,$CLJS.sd(b),$CLJS.Hk,k):F5(a,b)}return a};
I5=function(a,b){var c=$CLJS.X3.g(a,b),d=$CLJS.lh(0,$CLJS.D(c));return $CLJS.gf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PR],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.iW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.QE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WF],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$P],null)],null),function(){return function k(f){return new $CLJS.ne(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Ad(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.F(c,x);$CLJS.QE.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,x,$CLJS.QE],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);m=$CLJS.F(c,u);if($CLJS.QE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,u,$CLJS.QE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.ne(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,x,$CLJS.qL],null));v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,u,$CLJS.qL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
J5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.mG),k=$CLJS.J.g(e,$CLJS.vR),l=$CLJS.I(c,2,null);return $CLJS.A($CLJS.tH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.A(m),d)&&$CLJS.E.g($CLJS.mG.h($CLJS.hd(m)),f)&&$CLJS.E.g($CLJS.vR.h($CLJS.hd(m)),k)&&$CLJS.E.g($CLJS.id(m),l)},$CLJS.dm($CLJS.Ks,$CLJS.PR.h($CLJS.FW(a,b)))))};
Uqa=function(a,b,c,d){c=J5(a,b,c);return $CLJS.n(c)?$CLJS.QW.l(a,b,$CLJS.vX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.PR,c,2,1],null),$CLJS.Pk.g(function(e){return $CLJS.s1($CLJS.Al,e)},$CLJS.gl),d])):a};Vqa=function(a,b,c){c=J5(a,b,c);return $CLJS.n(c)?$CLJS.QW.l(a,b,H5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.PR],null),$CLJS.zA($CLJS.FW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PR,c],null)),b])):a};
M5=function(a,b,c,d,e,f){var k=$CLJS.QW.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.G5(e);return $CLJS.Nk.g(a,k)?$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.he(u,$CLJS.$P))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.yW(e);K=K5.W?K5.W(k,b,c,$CLJS.mB,$CLJS.N,K):K5.call(null,k,b,c,$CLJS.mB,$CLJS.N,K);return L5.v?L5.v(K,b,c,l):L5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.he(u,$CLJS.WF))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=K5.W?K5.W(k,b,c,$CLJS.WF,$CLJS.N,l):K5.call(null,k,b,c,$CLJS.WF,$CLJS.N,l);return L5.v?L5.v(S,b,c,l):L5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.VF))return new $CLJS.P(null,
1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.he(x,$CLJS.QE))return new $CLJS.P(null,1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){var z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.he(C,$CLJS.EN))try{var G=$CLJS.F(t,2);if($CLJS.he(G,$CLJS.QE))return new $CLJS.P(null,1,5,$CLJS.Q,[L5.v?L5.v(k,b,c,l):L5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw V;}else throw $CLJS.Y;}catch(V){if(V instanceof Error){z=V;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw z;}throw V;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Cf,d)))):k};
K5=function(a,b,c,d,e,f){var k=$CLJS.FW(a,b),l=$CLJS.jf(function(m){var t=$CLJS.zA(k,m);return $CLJS.n(t)?$CLJS.cm(function(u){return $CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function C(x,z){try{if($CLJS.zd(z)&&3===$CLJS.D(z))try{var G=$CLJS.F(z,0);if($CLJS.E.g(G,d))try{var K=$CLJS.F(z,1);if($CLJS.td(e)||$CLJS.r1($CLJS.fh(e),$CLJS.fh(K)))try{var S=$CLJS.F(z,2);if($CLJS.E.g(S,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var V=
Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)throw $CLJS.Y;throw V;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){V=Z;if(V===$CLJS.Y)return $CLJS.AV(C,x,z);throw V;}throw Z;}}($CLJS.Cf,u))))},t):null},$CLJS.H([I5(a,b)]));return $CLJS.eb(function(m,t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return M5(m,b,
c,u,t,function(v,x,z){return H5(v,x,z,b)})},a,l)};L5=function(a,b,c,d){b=$CLJS.z2(c,b);if($CLJS.n(b)){var e=$CLJS.FW(c,b);c=$CLJS.Re(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.OK),l=$CLJS.J.g(f,$CLJS.d1);return $CLJS.E.g($CLJS.xZ,k)&&$CLJS.E.g(d,l)?$CLJS.KZ.h(f):null},$CLJS.L0.j(c,b,e));return $CLJS.n(c)?K5(a,b,a,$CLJS.gG,$CLJS.N,c):a}return a};
N5=function(a,b,c,d,e){var f=$CLJS.rW.h(c),k=$CLJS.FW(a,b);c=$CLJS.tH(function(t){var u=$CLJS.zA(k,t);if($CLJS.n(u)){var v=$CLJS.G5(f);return $CLJS.n($CLJS.Re($CLJS.Pk.j($CLJS.ch([v]),$CLJS.xE,$CLJS.hd),u))?t:null}return null},I5(a,b));var l=(d=$CLJS.E.g($CLJS.EG,d))?$CLJS.rW.h(e):null;e=d?function(t,u,v){return Tqa(t,u,v,l)}:function(t,u,v){return H5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.VF],null),c);a=d&&m&&$CLJS.E.g($CLJS.A(f),$CLJS.A(l))&&$CLJS.E.g($CLJS.id(f),$CLJS.id(l))?
Uqa(a,b,f,$CLJS.em($CLJS.hd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vR,$CLJS.mG],null))):m?Vqa(a,b,f):a;return $CLJS.n(c)?M5(a,b,a,c,f,e):a};O5=function(a,b){return $CLJS.GY(a,$CLJS.gG)&&$CLJS.E.g($CLJS.E1(a),b)};Wqa=function(a,b,c){return function k(e,f){try{if(O5(f,b))return $CLJS.C3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.YW(k,e,f);throw l;}throw m;}}($CLJS.Cf,a)};
Xqa=function(a,b,c){var d=$CLJS.EN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.zA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.UE],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Nk.g(e,c):b)){var f=$CLJS.IW();$CLJS.y3(f,$CLJS.hf.g($CLJS.UE,d));c=f(c);return Wqa($CLJS.XL(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,b,$CLJS.UE],null),c),e,c)}return a};
Yqa=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.ch([c]);return"string"===typeof c?$CLJS.Pk.g(e,$CLJS.UE):e}();return $CLJS.Re(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.dm($CLJS.Ks,$CLJS.EN.h($CLJS.FW(a,b))))};
P5=function(a,b,c,d){b=$CLJS.eu.g($CLJS.fh($CLJS.L0.j(b,c,$CLJS.FW(b,c))),$CLJS.fh($CLJS.L0.j(a,c,$CLJS.FW(a,c))));return $CLJS.eb(function(e,f){return $CLJS.Se.N(K5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};Zqa=function(a,b,c){a=P5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,new $CLJS.h(null,1,[$CLJS.uP,$CLJS.ZZ.h(d)],null),$CLJS.Zi.h(d)],null)});c=$CLJS.z2(a,c);return $CLJS.n(c)?P5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,$CLJS.N,$CLJS.KZ.h(d)],null)}):a};
$qa=function(a,b,c){return $CLJS.Fd(c)?$CLJS.zA($CLJS.FW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EN,c,$CLJS.UE],null)):$CLJS.xd(c)?$CLJS.UE.h(c):c};Q5=function(a,b,c,d){var e=$qa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.QW)(a,b,function(k){var l=$CLJS.EN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.DE(k,$CLJS.EN,l)});return(0,$CLJS.QW)(f,b,function(k){return $CLJS.UW(k,$CLJS.EN,function(l){return $CLJS.Rk.g(function(m){return $CLJS.K3(f,b,m)},l)})})}(),Zqa(c,a,b)):a};
ara=function(a,b){return null!=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function f(d,e){try{if(O5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.AV(f,d,e);throw k;}throw l;}}($CLJS.Cf,a))))};bra=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.S5=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Ej.h(f),$CLJS.iL)?$CLJS.R5.j?$CLJS.R5.j(d,e,f):$CLJS.R5.call(null,d,e,f):N5(d,e,f,bra,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.U5=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.E.g($CLJS.Ej.h(f),$CLJS.iL)?$CLJS.T5.v?$CLJS.T5.v(d,e,f,k):$CLJS.T5.call(null,d,e,f,k):N5(d,e,f,$CLJS.EG,k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.cra=function(){function a(d,e,f,k){f=Yqa(d,e,f);return $CLJS.n(f)?$CLJS.QW.l(d,e,Xqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.R5=function(){function a(d,e,f){try{return Q5(d,e,f,function(u,v){return $CLJS.Fe($CLJS.Z0(function(x){return!($CLJS.E.g($CLJS.UE.h(x),v)||ara(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.hm(k)),m=$CLJS.J.g(l,$CLJS.DL),t=$CLJS.J.g(l,$CLJS.By);l=$CLJS.J.g(l,$CLJS.LW);if($CLJS.E.g(t,$CLJS.KW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.T5=function(){function a(d,e,f,k){return null==k?$CLJS.R5.j(d,e,f):Q5(d,e,f,function(l,m){return $CLJS.Rk.g(function(t){return $CLJS.E.g($CLJS.UE.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();