var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var h9,j9,i9,Wva,p9,Xva,q9,Yva,Zva,$va,awa,k9,bwa,v9,cwa,dwa,ewa;h9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Ca(a,b):null};j9=function(a,b){return i9(a,b,$CLJS.N)};
i9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,k9,!0);if($CLJS.xd(b))return $CLJS.eg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=h9(b,"_plainObject");d=null==d?null:$CLJS.Mz(d);return null==d?null:$CLJS.Fe(d)}():null;return $CLJS.n(c)?$CLJS.eg.j($CLJS.N,a,c):$CLJS.eg.j($CLJS.N,$CLJS.Pk.j($CLJS.hf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,h9(b,d)],null)}),$CLJS.Qk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ba(b))};
Wva=function(a){var b=l9.h(a),c=m9.h(a),d=n9.h(a);return $CLJS.Pk.j($CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.zh.h($CLJS.aB.h?$CLJS.aB.h(f):$CLJS.aB.call(null,f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Td:$CLJS.Qk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Id(b,f)}),$CLJS.Va(c)?$CLJS.Td:$CLJS.hf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,
1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
p9=function(a,b){var c=Wva(a),d=o9.h(a);return function(e){try{var f=$CLJS.R.j(i9(c,e,b),$CLJS.Ej,d),k=$CLJS.IA($CLJS.Ay);$CLJS.n($CLJS.HA("metabase.lib.js.metadata",k))&&$CLJS.GA("metabase.lib.js.metadata",k,$CLJS.uW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Zi.h(f),$CLJS.vW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.IA($CLJS.By),$CLJS.n($CLJS.HA("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.GA("metabase.lib.js.metadata",k,$CLJS.uW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.tV(f)])),f):$CLJS.GA("metabase.lib.js.metadata",k,$CLJS.uW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Mh.l($CLJS.H([e])),$CLJS.tV(f)])),null)),null;throw l;}}};Xva=function(a){return j9($CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.zh.h($CLJS.aB.h?$CLJS.aB.h(c):$CLJS.aB.call(null,c));c=$CLJS.E.g(c,$CLJS.CV)?$CLJS.BE:c;b=$CLJS.E.g(c,$CLJS.BE)?$CLJS.zh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
q9=function(a){return $CLJS.Rk.g(p9($CLJS.gG,$CLJS.N),a)};Yva=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.r9=new $CLJS.M(null,"cards","cards",169174038);Zva=new $CLJS.M(null,"binning-info","binning-info",-2117036653);$va=new $CLJS.M(null,"db","db",993250759);$CLJS.s9=new $CLJS.M(null,"metrics","metrics",394093469);awa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.t9=new $CLJS.M(null,"segments","segments",1937535949);
k9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);bwa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.u9=new $CLJS.M(null,"tables","tables",1334623052);v9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);cwa=new $CLJS.M(null,"schema-name","schema-name",1666725119);dwa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);ewa=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.w9=new $CLJS.M(null,"databases","databases",2040134125);var l9,fwa=$CLJS.Xe($CLJS.N),gwa=$CLJS.Xe($CLJS.N),hwa=$CLJS.Xe($CLJS.N),iwa=$CLJS.Xe($CLJS.N),jwa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null)),y9,zwa,Awa,Bwa,Cwa,Dwa;l9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.zh,jwa,fwa,gwa,hwa,iwa);l9.m(null,$CLJS.ci,function(){return null});var m9,kwa=$CLJS.Xe($CLJS.N),lwa=$CLJS.Xe($CLJS.N),mwa=$CLJS.Xe($CLJS.N),nwa=$CLJS.Xe($CLJS.N),owa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
m9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.zh,owa,kwa,lwa,mwa,nwa);m9.m(null,$CLJS.ci,function(){return null});var o9,pwa=$CLJS.Xe($CLJS.N),qwa=$CLJS.Xe($CLJS.N),rwa=$CLJS.Xe($CLJS.N),swa=$CLJS.Xe($CLJS.N),twa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));o9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","lib-type"),$CLJS.zh,twa,pwa,qwa,rwa,swa);
var n9,uwa=$CLJS.Xe($CLJS.N),vwa=$CLJS.Xe($CLJS.N),wwa=$CLJS.Xe($CLJS.N),xwa=$CLJS.Xe($CLJS.N),ywa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));n9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Td,ywa,uwa,vwa,wwa,xwa);n9.m(null,$CLJS.ci,function(){return null});
$CLJS.x9=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.zh.h(f)},e,a,b,c,d)}();zwa=$CLJS.Xe($CLJS.N);Awa=$CLJS.Xe($CLJS.N);Bwa=$CLJS.Xe($CLJS.N);Cwa=$CLJS.Xe($CLJS.N);Dwa=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
y9=new $CLJS.di($CLJS.Fh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.zh,Dwa,zwa,Awa,Bwa,Cwa);$CLJS.x9.m(null,$CLJS.ci,function(a,b){var c=p9(a,$CLJS.N);return j9($CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),h9(b,y9.h(a)))});o9.m(null,$CLJS.VP,function(){return $CLJS.cL});
l9.m(null,$CLJS.VP,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.QE,null,$CLJS.u9,null],null),null)});m9.m(null,$CLJS.VP,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.Lz(b,$CLJS.H([$CLJS.yi,!0]));case "features":return $CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.zh),b);case "native-permissions":return $CLJS.zh.h(b);default:return b}}});y9.m(null,$CLJS.VP,function(){return"databases"});o9.m(null,$CLJS.OW,function(){return $CLJS.TK});
l9.m(null,$CLJS.OW,function(){return new $CLJS.ah(null,new $CLJS.h(null,5,[v9,null,$CLJS.QE,null,$CLJS.t9,null,$CLJS.VP,null,$CLJS.s9,null],null),null)});m9.m(null,$CLJS.OW,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.zh.h(b);case "field-order":return $CLJS.zh.h(b);case "initial-sync-status":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);default:return b}}});
$CLJS.x9.m(null,$CLJS.OW,function(a,b){var c=p9(a,$CLJS.N);return j9($CLJS.Pk.g($CLJS.Qk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.hf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tW(e),new $CLJS.Jh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),h9(b,"tables"))});o9.m(null,$CLJS.gG,function(){return $CLJS.MK});
l9.m(null,$CLJS.gG,function(){return new $CLJS.ah(null,new $CLJS.h(null,7,[v9,null,$CLJS.OW,null,$CLJS.GL,null,ewa,null,$CLJS.VP,null,dwa,null,$CLJS.s9,null],null),null)});n9.m(null,$CLJS.gG,function(){return new $CLJS.h(null,4,[$CLJS.Zz,$CLJS.OK,$CLJS.ri,$CLJS.JW,$CLJS.bS,$CLJS.pE,Zva,$CLJS.PW],null)});
m9.m(null,$CLJS.gG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.zh.h(b);case "coercion-strategy":return $CLJS.zh.h(b);case "effective-type":return $CLJS.zh.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.vV(b):$CLJS.Lz(b,$CLJS.H([$CLJS.yi,!0]));case "has-field-values":return $CLJS.zh.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.gL;case "breakout":return $CLJS.aL;default:return $CLJS.zh.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.zh.h(b);
case "semantic-type":return $CLJS.zh.h(b);case "visibility-type":return $CLJS.zh.h(b);case "id":return $CLJS.zd(b)?$CLJS.hd(b):b;case "metabase.lib.field/binning":return Xva(b);default:return b}}});
$CLJS.x9.m(null,$CLJS.gG,function(a,b){var c=p9(a,$CLJS.N),d=h9(b,"fields");return j9($CLJS.Xl(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.tW(f);if($CLJS.n(l))return l;l=$CLJS.nh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==h9(d,l);return m?$CLJS.tW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Jh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});o9.m(null,$CLJS.wN,function(){return $CLJS.fL});
l9.m(null,$CLJS.wN,function(){return new $CLJS.ah(null,new $CLJS.h(null,11,[v9,null,$CLJS.pl,null,awa,null,$CLJS.OW,null,$va,null,Yva,null,$CLJS.t9,null,$CLJS.VP,null,bwa,null,$CLJS.s9,null,cwa,null],null),null)});
m9.m(null,$CLJS.wN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.yA($CLJS.wd,$CLJS.Ta)(b))?q9(b):$CLJS.Lz(b,$CLJS.H([$CLJS.yi,!0]));case "fields":return q9(b);case "visibility-type":return $CLJS.zh.h(b);case "dataset-query":return $CLJS.Lz(b,$CLJS.H([$CLJS.yi,!0]));case "dataset":return b;default:return b}}});
$CLJS.x9.m(null,$CLJS.wN,function(a,b){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Jh(function(){var d=p9($CLJS.wN,new $CLJS.h(null,1,[k9,!1],null)),e=p9($CLJS.wN,$CLJS.N),f=$CLJS.gl.l;d=$CLJS.R.j(d(h9(h9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Zi,c);var k=h9(h9(b,"questions"),$CLJS.p.h(c));var l=h9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.gl,$CLJS.H([d,e]))})],null)}),$CLJS.eg.j($CLJS.eg.j($CLJS.bh,
$CLJS.Xl($CLJS.GW),$CLJS.Ba(h9(b,"tables"))),$CLJS.hf.h($CLJS.tW),$CLJS.Ba(h9(b,"questions"))))});o9.m(null,$CLJS.bG,function(){return $CLJS.SK});l9.m(null,$CLJS.bG,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.OW,null,$CLJS.VP,null],null),null)});m9.m(null,$CLJS.bG,function(){return function(a,b){return b}});y9.m(null,$CLJS.bG,function(){return"metrics"});o9.m(null,$CLJS.FF,function(){return $CLJS.QK});
l9.m(null,$CLJS.FF,function(){return new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.OW,null,$CLJS.VP,null],null),null)});m9.m(null,$CLJS.FF,function(){return function(a,b){return b}});y9.m(null,$CLJS.FF,function(){return"segments"});$CLJS.z9=p9($CLJS.gG,$CLJS.N);