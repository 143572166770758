var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var g4,i4,j4,k4,l4,m4,Tpa;g4=function(a,b,c){var d=$CLJS.FW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.WF);d=$CLJS.tH($CLJS.Pk.j($CLJS.ch([c]),$CLJS.xE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.gE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Fs,c,$CLJS.DF,a,$CLJS.DL,b],null));return d};$CLJS.h4=function(a){return $CLJS.a2($CLJS.tG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
i4=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);j4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);k4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);l4=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);
m4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Tpa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.D0.m(null,$CLJS.WF,function(a,b){var c=$CLJS.Fe($CLJS.WF.h($CLJS.FW(a,b)));return $CLJS.n(c)?$CLJS.c2($CLJS.IE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.a0.v(a,b,v,$CLJS.b0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.a0.v(a,
b,t,$CLJS.b0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.G0.m(null,$CLJS.WF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.pB);e=$CLJS.J.g(e,$CLJS.Ei);c=$CLJS.I(c,2,null);c=g4(a,b,c);return $CLJS.gl.l($CLJS.H([$CLJS.$_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.OK,$CLJS.gL,$CLJS.d1,$CLJS.xE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.pB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Ei,e],null):null]))});
$CLJS.A0.m(null,$CLJS.WF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.a0.v(a,b,g4(a,b,c),d)});$CLJS.oF(k4,m4);
for(var n4=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.hG,$CLJS.pG],null)),o4=null,p4=0,q4=0;;)if(q4<p4){var Upa=o4.X(null,q4);$CLJS.oF(Upa,k4);q4+=1}else{var r4=$CLJS.y(n4);if(r4){var s4=r4;if($CLJS.Ad(s4)){var t4=$CLJS.lc(s4),Vpa=$CLJS.mc(s4),Wpa=t4,Xpa=$CLJS.D(t4);n4=Vpa;o4=Wpa;p4=Xpa}else{var Ypa=$CLJS.A(s4);$CLJS.oF(Ypa,k4);n4=$CLJS.B(s4);o4=null;p4=0}q4=0}else break}
$CLJS.A0.m(null,k4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.a0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.gE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.gE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.IE("Count");case "cum-count":return $CLJS.IE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.B0.m(null,k4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.G0.m(null,l4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.mI($CLJS.G0,m4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Vj,$CLJS.SC)});
$CLJS.oF(l4,m4);$CLJS.oF(k4,l4);$CLJS.oF($CLJS.MF,l4);$CLJS.A0.m(null,$CLJS.QF,function(){return $CLJS.IE("Case")});$CLJS.B0.m(null,$CLJS.QF,function(){return"case"});$CLJS.oF(j4,m4);
for(var u4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.aG,$CLJS.CG,$CLJS.MF,$CLJS.jk,$CLJS.uG,$CLJS.Zn,$CLJS.vG,$CLJS.tG,$CLJS.NF],null)),v4=null,w4=0,x4=0;;)if(x4<w4){var Zpa=v4.X(null,x4);$CLJS.oF(Zpa,j4);x4+=1}else{var y4=$CLJS.y(u4);if(y4){var z4=y4;if($CLJS.Ad(z4)){var A4=$CLJS.lc(z4),$pa=$CLJS.mc(z4),aqa=A4,bqa=$CLJS.D(A4);u4=$pa;v4=aqa;w4=bqa}else{var cqa=$CLJS.A(z4);$CLJS.oF(cqa,j4);u4=$CLJS.B(z4);v4=null;w4=0}x4=0}else break}
$CLJS.B0.m(null,j4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.A0.m(null,j4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.a0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.gE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.gE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.gE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.gE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.gE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.gE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.gE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.gE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.gE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.A0.m(null,$CLJS.SF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.gE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.a0.v(a,b,e,d)]))});$CLJS.B0.m(null,$CLJS.SF,function(){return"percentile"});
$CLJS.oF(i4,m4);for(var B4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SF,$CLJS.NF],null)),C4=null,D4=0,E4=0;;)if(E4<D4){var dqa=C4.X(null,E4);$CLJS.oF(dqa,i4);E4+=1}else{var F4=$CLJS.y(B4);if(F4){var G4=F4;if($CLJS.Ad(G4)){var H4=$CLJS.lc(G4),eqa=$CLJS.mc(G4),fqa=H4,gqa=$CLJS.D(H4);B4=eqa;C4=fqa;D4=gqa}else{var hqa=$CLJS.A(G4);$CLJS.oF(hqa,i4);B4=$CLJS.B(G4);C4=null;D4=0}E4=0}else break}
$CLJS.G0.m(null,i4,function(a,b,c){var d=$CLJS.Hk.g,e=$CLJS.mI($CLJS.G0,m4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Hk,a,$CLJS.Vj)});$CLJS.A0.m(null,$CLJS.AG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.gE("Sum of {0} matching condition",$CLJS.H([$CLJS.a0.v(a,b,e,d)]))});
$CLJS.B0.m(null,$CLJS.AG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.C0.j(a,b,d))].join("")});$CLJS.oF($CLJS.AG,m4);$CLJS.A0.m(null,$CLJS.nG,function(){return $CLJS.IE("Share of rows matching condition")});$CLJS.B0.m(null,$CLJS.nG,function(){return"share"});$CLJS.G0.m(null,$CLJS.nG,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.mI($CLJS.G0,m4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Vj,$CLJS.kD)});
$CLJS.oF($CLJS.nG,m4);$CLJS.A0.m(null,$CLJS.pG,function(){return $CLJS.IE("Count of rows matching condition")});$CLJS.G0.m(null,m4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.gl.l;d=$CLJS.n(d)?$CLJS.em($CLJS.$_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$K,$CLJS.Vj],null)):null;var f=$CLJS.mI($CLJS.G0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.gl,$CLJS.H([d,a]))});
$CLJS.iqa=function(){function a(d){return $CLJS.a2($CLJS.Qx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.a2($CLJS.Qx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.oW.m(null,$CLJS.WF,function(a){return a});
$CLJS.I4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.cC(f),$CLJS.SK))f=$CLJS.qW(f);else return $CLJS.d2(d,e,$CLJS.WF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.J4=function(){function a(d,e){return $CLJS.Fe($CLJS.WF.h($CLJS.FW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.K4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.WF.h($CLJS.FW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.$_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.pB.h(l);return m.call($CLJS.R,$CLJS.$1(l,$CLJS.Ei,$CLJS.n(t)?t:$CLJS.Aj),$CLJS.OK,$CLJS.gL,$CLJS.H([$CLJS.d1,$CLJS.xE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.A0.m(null,$CLJS.PG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.LG);return $CLJS.lE.h(a.o?a.o():a.call(null))});$CLJS.H0.m(null,$CLJS.PG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.$F);b=$CLJS.J.g(d,$CLJS.LG);c=$CLJS.J.g(d,$CLJS.JG);d=$CLJS.J.g(d,$CLJS.MZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.y0,$CLJS.WA(a),$CLJS.H([$CLJS.w0,c]));return null!=d?$CLJS.R.j(a,$CLJS.i0,d):a});
$CLJS.jqa=function(){function a(d,e){var f=function(){var m=$CLJS.eA.h($CLJS.u1($CLJS.U_(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.FW(d,e),l=$CLJS.L0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Pk.j($CLJS.kf(function(m){m=$CLJS.NG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Xl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.JG),u=$CLJS.J.g(m,$CLJS.HG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.Hs))return $CLJS.R.j(m,$CLJS.CW,l);t=$CLJS.Fe($CLJS.Z0(function(v){return $CLJS.w_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.CW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.Ej,$CLJS.PG)})),$CLJS.QG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.kqa=function(){function a(d,e){return $CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F.h(d),$CLJS.N,$CLJS.rW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.JG.h(d)))return $CLJS.pW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F.h(d),$CLJS.N],null));var e=$CLJS.$F.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Tpa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();