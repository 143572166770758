var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.QJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.RJ=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.SJ=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var TJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.rs],null)),UJ=null,VJ=0,WJ=0;;)if(WJ<VJ){var fha=UJ.X(null,WJ);$CLJS.xF(fha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)],null)],null)]));WJ+=1}else{var XJ=$CLJS.y(TJ);if(XJ){var YJ=XJ;if($CLJS.Ad(YJ)){var ZJ=$CLJS.lc(YJ),gha=$CLJS.mc(YJ),
hha=ZJ,iha=$CLJS.D(ZJ);TJ=gha;UJ=hha;VJ=iha}else{var jha=$CLJS.A(YJ);$CLJS.xF(jha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)],null)],null)]));TJ=$CLJS.B(YJ);UJ=null;VJ=0}WJ=0}else break}$CLJS.vF($CLJS.ts,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)]));
for(var $J=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.xG],null)),aK=null,bK=0,cK=0;;)if(cK<bK){var kha=aK.X(null,cK);$CLJS.xF(kha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],null)],null)]));cK+=1}else{var dK=$CLJS.y($J);if(dK){var eK=dK;if($CLJS.Ad(eK)){var fK=$CLJS.lc(eK),lha=$CLJS.mc(eK),
mha=fK,nha=$CLJS.D(fK);$J=lha;aK=mha;bK=nha}else{var oha=$CLJS.A(eK);$CLJS.xF(oha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$E],null)],null)],null)],null)]));$J=$CLJS.B(eK);aK=null;bK=0}cK=0}else break}
for(var gK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Rr,$CLJS.Tr,$CLJS.Nr,$CLJS.Pr],null)),hK=null,iK=0,jK=0;;)if(jK<iK){var pha=hK.X(null,jK);$CLJS.vF(pha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));jK+=1}else{var kK=$CLJS.y(gK);if(kK){var lK=kK;if($CLJS.Ad(lK)){var mK=$CLJS.lc(lK),qha=$CLJS.mc(lK),rha=mK,sha=$CLJS.D(mK);gK=qha;hK=rha;iK=sha}else{var tha=$CLJS.A(lK);$CLJS.vF(tha,$CLJS.H([$CLJS.jt,$CLJS.Lj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));gK=$CLJS.B(lK);hK=null;iK=0}jK=0}else break}$CLJS.vF($CLJS.JF,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));
$CLJS.vF($CLJS.PF,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)]));
for(var nK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IF,$CLJS.TF],null)),oK=null,pK=0,qK=0;;)if(qK<pK){var uha=oK.X(null,qK);$CLJS.vF(uha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)]));qK+=1}else{var rK=$CLJS.y(nK);if(rK){var sK=rK;if($CLJS.Ad(sK)){var tK=$CLJS.lc(sK),vha=$CLJS.mc(sK),wha=tK,xha=$CLJS.D(tK);nK=vha;oK=wha;pK=xha}else{var yha=$CLJS.A(sK);$CLJS.vF(yha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)]));nK=$CLJS.B(sK);
oK=null;pK=0}qK=0}else break}
for(var uK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KF,$CLJS.RF],null)),vK=null,wK=0,xK=0;;)if(xK<wK){var zha=vK.X(null,xK);$CLJS.vF(zha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)]));xK+=1}else{var yK=$CLJS.y(uK);if(yK){var zK=yK;if($CLJS.Ad(zK)){var AK=$CLJS.lc(zK),Aha=$CLJS.mc(zK),Bha=AK,Cha=$CLJS.D(AK);uK=Aha;vK=Bha;wK=Cha}else{var Dha=$CLJS.A(zK);$CLJS.vF(Dha,$CLJS.H([$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)]));uK=$CLJS.B(zK);
vK=null;wK=0}xK=0}else break}
for(var BK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null),CK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YF,$CLJS.rG,$CLJS.fG,$CLJS.ZF],null)),DK=null,EK=0,FK=0;;)if(FK<EK){var GK=DK.X(null,FK);$CLJS.GG.v(GK,$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,GK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.vE,BK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.eF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null));FK+=1}else{var HK=$CLJS.y(CK);if(HK){var IK=HK;if($CLJS.Ad(IK)){var JK=$CLJS.lc(IK),Eha=$CLJS.mc(IK),Fha=JK,Gha=$CLJS.D(JK);CK=Eha;DK=Fha;EK=Gha}else{var KK=$CLJS.A(IK);$CLJS.GG.v(KK,$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,KK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.vE,BK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.eF],null)],null));CK=$CLJS.B(IK);DK=null;EK=0}FK=0}else break}
$CLJS.GG.v($CLJS.sG,$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.sG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,$CLJS.vE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.os,$CLJS.Sv,$CLJS.Ux,$CLJS.OF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.hF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aH],null)],null));$CLJS.GG.v($CLJS.FF,$CLJS.jt,$CLJS.Lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.us,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.FF],null),$CLJS.vE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.iE,$CLJS.qE],null)],null));
$CLJS.X($CLJS.QJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.RJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.os,$CLJS.Ur,$CLJS.xG,$CLJS.PF,$CLJS.JF,$CLJS.Rr,$CLJS.Nr,$CLJS.Tr,$CLJS.Pr,$CLJS.IF,$CLJS.TF,$CLJS.KF,$CLJS.RF,$CLJS.fG,$CLJS.ZF,$CLJS.YF,$CLJS.rG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SJ,$CLJS.Xi],null)],null));