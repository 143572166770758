var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var uZ,Rma,Sma,Tma,Uma,Vma,AZ,Wma,Xma,Yma,DZ,Zma,$ma,ana,IZ,JZ,bna,cna,dna,SZ,ena,fna,gna,VZ,hna,ina,jna,kna;uZ=function(){};$CLJS.vZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.$a(uZ,a):$CLJS.$a(uZ,a)};Rma=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.wZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Sma=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Tma=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.xZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.yZ=new $CLJS.M(null,"database-id","database-id",1883826326);Uma=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.zZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Vma=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);AZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Wma=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Xma=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
Yma=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.BZ=new $CLJS.M("metabase.lib.schema.metadata","column","metabase.lib.schema.metadata/column",-570344650);$CLJS.CZ=new $CLJS.M(null,"dataset","dataset",1159262238);DZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Zma=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.EZ=new $CLJS.M("source","expressions","source/expressions",-458367840);
$CLJS.FZ=new $CLJS.M(null,"definition","definition",-1198729982);$ma=new $CLJS.M(null,"state","state",-1988618099);$CLJS.GZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);ana=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.HZ=new $CLJS.M("source","joins","source/joins",1225821486);IZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);
JZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.KZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.LZ=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);bna=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.MZ=new $CLJS.M(null,"selected?","selected?",-742502788);
cna=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);dna=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.NZ=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.OZ=new $CLJS.M("source","card","source/card",-139977973);$CLJS.PZ=new $CLJS.M(null,"active","active",1895962068);$CLJS.QZ=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.RZ=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);SZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);ena=new $CLJS.M(null,"details","details",1956795411);$CLJS.TZ=new $CLJS.M("source","fields","source/fields",-649667981);fna=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.UZ=new $CLJS.M("source","native","source/native",-1444604147);
gna=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);VZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.WZ=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);hna=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);ina=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
jna=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);kna=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.XZ=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.YZ=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(IZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.os,$CLJS.OZ,$CLJS.UZ,$CLJS.xZ,$CLJS.zZ,$CLJS.TZ,$CLJS.gL,$CLJS.aL,$CLJS.HZ,$CLJS.EZ,$CLJS.GZ],null));$CLJS.X(SZ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,kna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.UK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VK,$CLJS.eL],null)],null));
$CLJS.X(AZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,hna],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.UK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Hs],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Vma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.Hs],null)],null)],null));
$CLJS.X($CLJS.BZ,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.MK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.Zj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.zE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zi,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.eL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Zj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ei,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.zE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YK,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.eL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.eL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XZ,
new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OK,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.WK],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zj,new $CLJS.h(null,2,[$CLJS.Zn,1,$CLJS.jk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[bna,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[jna,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AZ],null)],null)],null)],null));
$CLJS.X(VZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Zma,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[Yma,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.zE],null)],null)],null)],null)],null)],null));
$CLJS.X(DZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PZ,$CLJS.Gs],null),new $CLJS.P(null,2,5,$CLJS.Q,[$ma,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NZ,$CLJS.qE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LK,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,
$CLJS.qE],null)],null)],null));
$CLJS.X(fna,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.fL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yZ,$CLJS.XK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.ik],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,
new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,$CLJS.ik],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZK,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.RK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tma,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,DZ],null)],null)],null)],null));
$CLJS.X(cna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.QK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.PK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZK,$CLJS.RK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Kt,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null)],null));
$CLJS.X(dna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.SK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.NK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZK,$CLJS.RK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.Kt,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null)],null));
$CLJS.X(gna,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.TK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.RK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.qE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lE,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pl,new $CLJS.h(null,
1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.qE],null)],null)],null));
$CLJS.X(Sma,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.cL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zi,$CLJS.XK],null),new $CLJS.P(null,3,5,$CLJS.Q,[Xma,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ena,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.ik],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.YZ,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Xi],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eA,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,$CLJS.Xi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ana,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.ik],null)],null)],null));
$CLJS.X(JZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.vZ},Uma,$CLJS.Ig([$CLJS.Wi,$CLJS.T,$CLJS.pk,$CLJS.ui,$CLJS.Si,$CLJS.nj,$CLJS.yk,$CLJS.ti,$CLJS.kk,$CLJS.ok],[Rma,ina,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Sq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.vZ)?$CLJS.vZ.H:null]))],null));
$CLJS.X(Wma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ik,new $CLJS.h(null,1,[$CLJS.qt,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JZ],null)],null)],null)],null));