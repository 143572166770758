var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var DY,ema,FY,fma,gma,hma,HY,jma,kma,lma,mma,KY,LY,MY,NY,PY,oma,QY,RY,pma,qma,rma,JY,ima,SY,TY,nma,VY;DY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
ema=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.j?$CLJS.mj.j(k,l,m):$CLJS.mj.call(null,k,l,m),$CLJS.ml.j?$CLJS.ml.j(k,l,m):$CLJS.ml.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.g?$CLJS.mj.g(k,l):$CLJS.mj.call(null,k,l),$CLJS.ml.g?$CLJS.ml.g(k,l):$CLJS.ml.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.h?$CLJS.mj.h(k):$CLJS.mj.call(null,k),$CLJS.ml.h?$CLJS.ml.h(k):$CLJS.ml.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.o?$CLJS.mj.o():$CLJS.mj.call(null),$CLJS.ml.o?$CLJS.ml.o():$CLJS.ml.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.mj,m,t,u,v),$CLJS.Se.N($CLJS.ml,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.EY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
FY=function(a){return $CLJS.RL($CLJS.lW)(a,$CLJS.Cf,$CLJS.Cf)};fma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.WF,b),$CLJS.gt(2,2,c))};gma=function(a,b){var c=$CLJS.fm(a,$CLJS.HQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.At.g?$CLJS.At.g(c,b):$CLJS.At.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.HQ,b)}return a};hma=function(a){return $CLJS.gf.g($CLJS.MV(a),$CLJS.NV(a))};
$CLJS.GY=function(a,b){return $CLJS.xW(a)&&$CLJS.E.g($CLJS.A(a),b)};HY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Os,null,$CLJS.tl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.uu(2,c):$CLJS.uu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?DY(2,c):DY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.vX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Qk.h($CLJS.Al),l):$CLJS.xd(k)?$CLJS.FA($CLJS.Wa,l):l}):$CLJS.Hk.g(a,f)};
jma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return HY(b,ima,d)},a,hma(a))};
kma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.nL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(ema(),$CLJS.lf($CLJS.Pk.j(IY,$CLJS.A,$CLJS.ml),$CLJS.Ss.h(FY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=HY(d,f,k),m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.convert",m))){var t=$CLJS.yB.l($CLJS.H([$CLJS.yz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.yB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.WL(FY(d));$CLJS.GA("metabase.lib.convert",
m,$CLJS.uW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yz,z.call(x,$CLJS.H([C]))])),$CLJS.yB.l($CLJS.H([$CLJS.nz,$CLJS.vW($CLJS.A($CLJS.bu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=jma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.nL.h(a))-1))return a;b+=1}else a=$CLJS.Sk.N(a,$CLJS.nL,$CLJS.R,b,d)}};
lma=function(a){var b=$CLJS.IW();return $CLJS.Rk.g(function(c){return $CLJS.E.g($CLJS.UE.h(c),"__join")?$CLJS.Sk.j(c,$CLJS.UE,b):c},a)};mma=function(a){return"string"===typeof $CLJS.fP.h(a)?$CLJS.Hk.g($CLJS.R.j(a,$CLJS.OV,$CLJS.GW($CLJS.fP.h(a))),$CLJS.fP):a};KY=function(a,b){var c=$CLJS.J.j(a,b,JY);if($CLJS.E.g(c,JY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[nma,a,$CLJS.Uw,b],null));return c};
LY=function(){return $CLJS.Qk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};MY=function(a){return $CLJS.eg.j($CLJS.N,LY(),a)};NY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Pk.g(LY(),$CLJS.Qk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
PY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.OY),c);a=$CLJS.E.g(a,$CLJS.QF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(NY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vM,a,b],null):a};oma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Pk.g($CLJS.hf.h(function(b){return $CLJS.AA(b,$CLJS.DB)}),$CLJS.hf.h($CLJS.OY)),$CLJS.CW.h(a))};
QY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.nL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.OY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.JQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.SO,oma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.oV.h(c)],null)},null,a));return $CLJS.n($CLJS.jQ.h(a))?$CLJS.zV(a,new $CLJS.h(null,1,[$CLJS.jQ,$CLJS.DF],null)):a};
RY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.UW(a,b,$CLJS.Pk.g($CLJS.OY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.UW(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xs],null),$CLJS.hf.h($CLJS.OY),e)}):d;return $CLJS.zV(a,$CLJS.Ee([b,c]))};pma=function(a){var b=$CLJS.OV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Hk.g(a,$CLJS.OV),$CLJS.fP,["card__",$CLJS.p.h(b)].join("")):a};
qma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);rma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);JY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ima=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);SY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
TY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.UY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);nma=new $CLJS.M(null,"m","m",1632677161);VY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var IY,sma,tma,uma,vma,wma,xma,yma,zma,Ama,Bma;$CLJS.WY=$CLJS.N;$CLJS.XY=$CLJS.N;IY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.iW,null,$CLJS.QE,null,$CLJS.EN,null,$CLJS.$P,null,$CLJS.VF,null,$CLJS.PR,null,$CLJS.WF,null],null),null);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.Xe($CLJS.N);wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.eC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.YY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.cC,wma,sma,tma,uma,vma);$CLJS.YY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.pW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.YY),d))}else b=a;return b});$CLJS.YY.m(null,$CLJS.hW,function(a){return a});
$CLJS.YY.m(null,$CLJS.VV,function(a){var b=$CLJS.YY.h($CLJS.WF.h(a)),c=$CLJS.Fe($CLJS.Rk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.zW($CLJS.YY.h(t),u)},$CLJS.$P.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.xE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.xE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.XY,k=$CLJS.WY;$CLJS.XY=d;$CLJS.WY=e;try{var l=fma(mma(a),b,$CLJS.H([$CLJS.$P,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Sk.j(t,u,$CLJS.YY)},l,$CLJS.Ik.l(IY,$CLJS.WF,$CLJS.H([$CLJS.$P])));return $CLJS.n($CLJS.EN.h(m))?$CLJS.Sk.j(m,$CLJS.EN,lma):m}finally{$CLJS.WY=k,$CLJS.XY=f}});$CLJS.YY.m(null,$CLJS.bW,function(a){return gma(a,function(b){return $CLJS.UW(b,$CLJS.AF,$CLJS.YY)})});
$CLJS.YY.m(null,$CLJS.iL,function(a){a=$CLJS.Sk.j($CLJS.Sk.j(a,$CLJS.qL,$CLJS.YY),$CLJS.nL,$CLJS.YY);var b=$CLJS.n($CLJS.QE.h(a))?$CLJS.Sk.j(a,$CLJS.QE,function(c){return $CLJS.Ml(c)?$CLJS.Rk.g($CLJS.YY,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.UE.h(a))?$CLJS.R.j(b,$CLJS.UE,"__join"):b});$CLJS.YY.m(null,$CLJS.jB,function(a){return $CLJS.Rk.g($CLJS.YY,a)});
$CLJS.YY.m(null,$CLJS.lB,function(a){return $CLJS.n($CLJS.mj.h(a))?kma($CLJS.R.j($CLJS.Sk.j($CLJS.DW(a),$CLJS.nL,function(b){return $CLJS.Rk.g($CLJS.YY,b)}),$CLJS.UY,!0)):$CLJS.At(a,$CLJS.YY)});$CLJS.YY.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,a,b],null))});
$CLJS.YY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.zV(a,new $CLJS.h(null,3,[$CLJS.dD,$CLJS.pB,$CLJS.EO,$CLJS.Vj,$CLJS.YR,$CLJS.sE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.pB.h(a),d=$CLJS.n(d)?d:$CLJS.LE(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null))});
$CLJS.YY.m(null,$CLJS.QF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,$CLJS.Hk.g(c,$CLJS.ci),$CLJS.Rk.g($CLJS.YY,b)],null);b=$CLJS.pW(b);return null!=a?$CLJS.be.g(b,$CLJS.YY.h(a)):b});$CLJS.YY.m(null,$CLJS.mB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.YY.m(null,$CLJS.WF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.pW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,KY($CLJS.XY,c)],null))});$CLJS.YY.m(null,$CLJS.vM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.YY.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.gl.l($CLJS.H([c,a]))],null),d)});xma=$CLJS.Xe($CLJS.N);yma=$CLJS.Xe($CLJS.N);
zma=$CLJS.Xe($CLJS.N);Ama=$CLJS.Xe($CLJS.N);Bma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.eC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.OY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.cC,Bma,xma,yma,zma,Ama);
$CLJS.OY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.OY,d);a=NY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.OY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.At(MY(a),$CLJS.OY):
a;return a});for(var ZY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[VY,TY],null)),$Y=null,aZ=0,bZ=0;;)if(bZ<aZ){var Cma=$Y.X(null,bZ);$CLJS.oF(Cma,SY);bZ+=1}else{var cZ=$CLJS.y(ZY);if(cZ){var dZ=cZ;if($CLJS.Ad(dZ)){var eZ=$CLJS.lc(dZ),Dma=$CLJS.mc(dZ),Ema=eZ,Fma=$CLJS.D(eZ);ZY=Dma;$Y=Ema;aZ=Fma}else{var Gma=$CLJS.A(dZ);$CLJS.oF(Gma,SY);ZY=$CLJS.B(dZ);$Y=null;aZ=0}bZ=0}else break}
for(var fZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Qx,$CLJS.aG,$CLJS.pG,$CLJS.MF,$CLJS.jk,$CLJS.uG,$CLJS.Zn,$CLJS.SF,$CLJS.nG,$CLJS.vG,$CLJS.tG,$CLJS.AG],null)),gZ=null,hZ=0,iZ=0;;)if(iZ<hZ){var Hma=gZ.X(null,iZ);$CLJS.oF(Hma,VY);iZ+=1}else{var jZ=$CLJS.y(fZ);if(jZ){var kZ=jZ;if($CLJS.Ad(kZ)){var lZ=$CLJS.lc(kZ),Ima=$CLJS.mc(kZ),Jma=lZ,Kma=$CLJS.D(lZ);fZ=Ima;gZ=Jma;hZ=Kma}else{var Lma=$CLJS.A(kZ);$CLJS.oF(Lma,VY);fZ=$CLJS.B(kZ);gZ=null;hZ=0}iZ=0}else break}
for(var mZ=$CLJS.y($CLJS.yf([$CLJS.Wr,$CLJS.jt,$CLJS.Xr,$CLJS.BG,$CLJS.QF,$CLJS.jG,$CLJS.HF,$CLJS.DG,$CLJS.yF,$CLJS.UF,$CLJS.CF,$CLJS.eG,$CLJS.lG,$CLJS.EF,$CLJS.YI,$CLJS.$I,$CLJS.Ey,$CLJS.UI,$CLJS.bJ,$CLJS.OI,$CLJS.fJ,$CLJS.TI,$CLJS.MI,$CLJS.eJ,$CLJS.mJ,$CLJS.iJ,$CLJS.SI,$CLJS.pJ,$CLJS.hJ,$CLJS.nJ,$CLJS.GF,$CLJS.zF,$CLJS.EG,$CLJS.YH,$CLJS.iG,$CLJS.Ox,$CLJS.wG,$CLJS.yG,$CLJS.FG,$CLJS.Dt,$CLJS.Jt],!0)),nZ=null,oZ=0,pZ=0;;)if(pZ<oZ){var Mma=nZ.X(null,pZ);$CLJS.oF(Mma,TY);pZ+=1}else{var qZ=$CLJS.y(mZ);
if(qZ){var rZ=qZ;if($CLJS.Ad(rZ)){var sZ=$CLJS.lc(rZ),Nma=$CLJS.mc(rZ),Oma=sZ,Pma=$CLJS.D(sZ);mZ=Nma;nZ=Oma;oZ=Pma}else{var Qma=$CLJS.A(rZ);$CLJS.oF(Qma,TY);mZ=$CLJS.B(rZ);nZ=null;oZ=0}pZ=0}else break}$CLJS.OY.m(null,SY,function(a){return PY(a)});$CLJS.OY.m(null,$CLJS.lB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Pk.g(LY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.OY.h(b)],null)})),a)});
$CLJS.OY.m(null,$CLJS.WF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=NY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,KY($CLJS.WY,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.tV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.OY.m(null,$CLJS.jB,function(a){return $CLJS.Rk.g($CLJS.OY,a)});$CLJS.OY.m(null,$CLJS.gG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,$CLJS.OY.h(a),NY(b)],null)});
$CLJS.OY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=NY($CLJS.zV(b,new $CLJS.h(null,3,[$CLJS.pB,$CLJS.dD,$CLJS.Vj,$CLJS.EO,$CLJS.sE,$CLJS.YR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null)});
$CLJS.OY.m(null,$CLJS.iL,function(a){var b=MY(a);a=0==$CLJS.UE.h(a).lastIndexOf("__join",0)?$CLJS.Hk.g(b,$CLJS.UE):b;return $CLJS.gl.l($CLJS.H([$CLJS.At($CLJS.Hk.l(a,$CLJS.nL,$CLJS.H([$CLJS.qL])),$CLJS.OY),RY($CLJS.em(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qL],null)),$CLJS.qL,$CLJS.RR),QY(a)]))});
$CLJS.OY.m(null,$CLJS.VV,function(a){var b=$CLJS.WF.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.xE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.xE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.XY,e=$CLJS.WY;$CLJS.XY=c;$CLJS.WY=b;try{return $CLJS.eb(function(f,k){return $CLJS.UW(f,
k,$CLJS.OY)},RY($CLJS.UW($CLJS.UW(pma(MY(a)),$CLJS.WF,function(f){return $CLJS.Rk.g(PY,f)}),$CLJS.$P,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.OY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yW(C),$CLJS.E.g($CLJS.Dj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.OY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yW(x),$CLJS.E.g($CLJS.Dj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.iW,$CLJS.LF),$CLJS.Ik.l(IY,$CLJS.WF,$CLJS.H([$CLJS.iW,$CLJS.$P])))}finally{$CLJS.WY=e,$CLJS.XY=d}});$CLJS.OY.m(null,$CLJS.bW,function(a){return $CLJS.At(MY(a),$CLJS.OY)});
$CLJS.OY.m(null,$CLJS.hW,function(a){try{var b=MY(a),c=$CLJS.vN.h(b),d=QY(b),e=$CLJS.E.g($CLJS.Ej.h($CLJS.id($CLJS.nL.h(a))),$CLJS.bW)?$CLJS.jQ:$CLJS.DF;return $CLJS.gl.l($CLJS.H([$CLJS.Hk.l(b,$CLJS.nL,$CLJS.H([$CLJS.vN,$CLJS.UY])),function(){var k=$CLJS.Ee([$CLJS.mj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.vN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.tV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.DF,a],null),f);}});
$CLJS.tZ=function(){function a(d,e,f){f=$CLJS.CY(null,$CLJS.Lz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.FW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.WF);k=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.xE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.gm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.xE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.XY,t=$CLJS.WY;$CLJS.XY=k;$CLJS.WY=l;try{try{return $CLJS.YY.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.tV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.DF,d,$CLJS.DL,e,rma,f,qma,$CLJS.XY],null),u);}}finally{$CLJS.WY=t,$CLJS.XY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();