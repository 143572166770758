var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var lfa,mfa,nfa,ofa,pfa,qfa,rfa,MG,sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa,OG,Afa;$CLJS.HG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);lfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.IG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);mfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
nfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.JG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.KG=new $CLJS.M(null,"column-name","column-name",551523580);ofa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.LG=new $CLJS.M(null,"display-info","display-info",-816930907);pfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
qfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);rfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);MG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);sfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);tfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ufa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
vfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);wfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);xfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);yfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.NG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);zfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
OG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.PG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Afa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.xF($CLJS.Qx,$CLJS.H([$CLJS.jt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)],null)],null)]));$CLJS.xF($CLJS.hG,$CLJS.H([$CLJS.jt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)],null)],null)]));
$CLJS.vF($CLJS.aG,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.vF($CLJS.MF,$CLJS.H([$CLJS.jt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WE],null)],null)]));$CLJS.vF($CLJS.pG,$CLJS.H([$CLJS.jt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)]));
$CLJS.vF($CLJS.jk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.oF($CLJS.jk,$CLJS.aF);$CLJS.vF($CLJS.uG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.oF($CLJS.uG,$CLJS.aF);$CLJS.vF($CLJS.Zn,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.oF($CLJS.Zn,$CLJS.aF);
$CLJS.X(MG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.qt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.vF($CLJS.SF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MG],null)]));$CLJS.oF($CLJS.SF,$CLJS.aF);
$CLJS.vF($CLJS.nG,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)]));$CLJS.vF($CLJS.vG,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.vF($CLJS.tG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));
$CLJS.vF($CLJS.CG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.oF($CLJS.tG,$CLJS.aF);$CLJS.oF($CLJS.CG,$CLJS.aF);$CLJS.vF($CLJS.AG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)]));$CLJS.oF($CLJS.AG,$CLJS.aF);
$CLJS.vF($CLJS.NF,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.X(OG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.rs,zfa,xfa,qfa,mfa,lfa,pfa,rfa,sfa,tfa,wfa,ufa,Afa,yfa,nfa,vfa,$CLJS.Dl],null));$CLJS.X($CLJS.IG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.Zn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OG],null)],null));
$CLJS.QG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.$F,$CLJS.Qx,$CLJS.JG,!1,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Count of rows"),$CLJS.KG,$CLJS.IE("Count"),$CLJS.Kt,$CLJS.IE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.tG,$CLJS.HG,$CLJS.fk,$CLJS.JG,!0,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Sum of ..."),$CLJS.KG,$CLJS.IE("Sum"),$CLJS.Kt,$CLJS.IE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.aG,$CLJS.HG,$CLJS.fk,$CLJS.JG,!0,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Average of ..."),$CLJS.KG,$CLJS.IE("Average"),$CLJS.Kt,$CLJS.IE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.uG,$CLJS.HG,$CLJS.fk,$CLJS.JG,!0,$CLJS.NG,$CLJS.cG,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Median of ..."),$CLJS.KG,$CLJS.IE("Median"),$CLJS.Kt,$CLJS.IE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.MF,$CLJS.HG,$CLJS.Hs,$CLJS.JG,!0,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Number of distinct values of ..."),$CLJS.KG,$CLJS.IE("Distinct values"),$CLJS.Kt,$CLJS.IE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.CG,$CLJS.HG,$CLJS.fk,$CLJS.JG,!0,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Cumulative sum of ..."),
$CLJS.KG,$CLJS.IE("Sum"),$CLJS.Kt,$CLJS.IE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.$F,$CLJS.hG,$CLJS.JG,!1,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Cumulative count of rows"),$CLJS.KG,$CLJS.IE("Count"),$CLJS.Kt,$CLJS.IE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.vG,$CLJS.HG,$CLJS.fk,$CLJS.JG,
!0,$CLJS.NG,$CLJS.qG,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Standard deviation of ..."),$CLJS.KG,$CLJS.IE("SD"),$CLJS.Kt,$CLJS.IE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.Zn,$CLJS.HG,$CLJS.Yi,$CLJS.JG,!0,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Minimum of ..."),$CLJS.KG,$CLJS.IE("Min"),$CLJS.Kt,$CLJS.IE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.$F,$CLJS.jk,$CLJS.HG,$CLJS.Yi,$CLJS.JG,!0,$CLJS.NG,$CLJS.BF,$CLJS.LG,function(){return new $CLJS.h(null,3,[$CLJS.lE,$CLJS.IE("Maximum of ..."),$CLJS.KG,$CLJS.IE("Max"),$CLJS.Kt,$CLJS.IE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ofa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.PG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.os],null),$CLJS.hf.h($CLJS.$F),$CLJS.QG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HG,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qs,$CLJS.Xi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JG,$CLJS.Gs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.NG,$CLJS.Xi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LG,$CLJS.md],null)],null));