var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var Dqa=function(a,b){return new $CLJS.ne(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.A(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Lc(d);else break a}return d},null,null)},Eqa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tu(a,b),Dqa(a,b)],null)},k5=function(a){var b=$CLJS.gl.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.d5);c=$CLJS.J.g(c,$CLJS.e5);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.Z4.h;d=$CLJS.zh.h(d);var e=$CLJS.q($CLJS.W4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.Z4,d);c=new $CLJS.h(null,2,[$CLJS.c5,c,$CLJS.f5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.f5,2],null);b=b.call($CLJS.gl,$CLJS.H([c,a]));return $CLJS.n($CLJS.g5.h(a))?$CLJS.R.l($CLJS.Hk.g(b,$CLJS.g5),$CLJS.f5,$CLJS.g5.h(a),$CLJS.H([$CLJS.c5,$CLJS.g5.h(a)])):b},l5=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=l5[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=l5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("NumberFormatter.format-number-basic",
a);}return a},Fqa=function(a,b){var c=$CLJS.Lz(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.yi,!0],null)]));a=$CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.mj.h(d),"exponentMinusSign")},c))?c:function(){var d=Eqa(function(f){return $CLJS.Nk.g($CLJS.mj.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.gf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.mj,"exponentPlusSign",$CLJS.Dj,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Se.g($CLJS.p,
$CLJS.hf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.mj);e=$CLJS.J.g(e,$CLJS.Dj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},Gqa=function(a,b){switch($CLJS.e5.h(b)){case "scientific":return function(c){return Fqa(a,c)};default:return function(c){return a.format(c)}}},m5=function(a,b,c,d,e){this.options=a;this.Ag=b;this.Dg=c;this.ee=d;this.ii=e;this.C=393216;this.I=0},Hqa=function(a,b){return $CLJS.Se.g($CLJS.p,function(){return function e(d){return new $CLJS.ne(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.kd(k,v),z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.mj);z=$CLJS.J.g(z,$CLJS.Dj);var C=new $CLJS.ah(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:z,m.add(x));v+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.mj);t=$CLJS.J.g(t,$CLJS.Dj);if($CLJS.n(function(){var v=new $CLJS.ah(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ee($CLJS.E.g(u,"integer")?b:t,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.Lz(a.Ag.formatToParts(1),$CLJS.H([$CLJS.yi,!0])))}())},n5=function(a){var b=$CLJS.E.g($CLJS.e5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.jl($CLJS.FA($CLJS.Wa,$CLJS.Ig([$CLJS.Aqa,
$CLJS.sqa,$CLJS.vqa,$CLJS.xqa,$CLJS.Cqa,$CLJS.d5,$CLJS.wqa,$CLJS.yqa,$CLJS.tqa,$CLJS.uqa],[$CLJS.f5.g(a,b),$CLJS.i5.h(a),$CLJS.h5.h(a),$CLJS.zqa.h(a),$CLJS.E.g($CLJS.e5.h(a),"scientific")?null:$CLJS.e5.g(a,"decimal"),$CLJS.d5.h(a),!0,$CLJS.c5.g(a,b),$CLJS.E.g($CLJS.e5.h(a),"scientific")?"scientific":null,$CLJS.j5.h(a)]))));var c=$CLJS.i5.h(a);var d=$CLJS.d5.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=Gqa(b,a);return new m5(a,b,c,d,$CLJS.N)},o5=function(a,b){return n5(k5(b)).Oe(null,a)},q5=
function(a,b){var c=$CLJS.Hk.l(b,$CLJS.xB,$CLJS.H([$CLJS.e5])),d=$CLJS.xA(a);if(0===a)return"0";if(1E3>d)return $CLJS.p5.g?$CLJS.p5.g(a,c):$CLJS.p5.call(null,a,c);b=$CLJS.A($CLJS.lf(function(f){return d>=$CLJS.A(f)},Iqa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.gl.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.c5,1,$CLJS.f5,1],null)]));return $CLJS.p5.g?$CLJS.p5.g(f,k):$CLJS.p5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},Jqa=function(a,b){var c=k5(b);if($CLJS.n(r5.h(c)))b=
r5.h(c);else if($CLJS.Va($CLJS.g5.h(c))&&$CLJS.Va($CLJS.c5.h(c))&&$CLJS.Nk.g($CLJS.e5.h(c),"currency")&&$CLJS.xA(a)<($CLJS.E.g($CLJS.e5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Hk.g(c,$CLJS.f5);c=$CLJS.j5.g(c,0);b=n5(b.call($CLJS.R,d,$CLJS.h5,2>c?2:c))}else b=n5(c);return l5(b,a)};m5.prototype.P=function(a,b){return new m5(this.options,this.Ag,this.Dg,this.ee,b)};m5.prototype.O=function(){return this.ii};
m5.prototype.Oe=function(a,b){a=this.ee.h?this.ee.h(b):this.ee.call(null,b);var c=$CLJS.Bqa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Nk.g(c,".,"):c)&&(b=$CLJS.A(c),c=$CLJS.hd(c),a=$CLJS.CA(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Dg)&&(b=$CLJS.d5.h(this.options),c=$CLJS.X4(b),a=$CLJS.CA($CLJS.CA(a,[$CLJS.gh(b)," "].join(""),c),$CLJS.gh(b),c));return a};
var r5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),s5=new $CLJS.M(null,"scale","scale",-230427353),t5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),Kqa=new $CLJS.M(null,"scientific","scientific",316285837);var u5=new $CLJS.h(null,1,[$CLJS.i5,"symbol"],null),Lqa=$CLJS.jl(u5),Iqa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),v5=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.e5)},e,a,b,c,d)}();v5.m(null,$CLJS.ci,function(a,b){return q5(a,b)});v5.m(null,"percent",function(a,b){return[$CLJS.p.h(q5(100*a,b)),"%"].join("")});v5.m(null,"currency",function(a,b){b=$CLJS.gl.l($CLJS.H([b,u5]));var c=n5(b);return 1E3>$CLJS.xA(a)?c.Oe(null,a):Hqa(c,q5(a,b))});v5.m(null,"scientific",function(a,b){return o5(a,$CLJS.gl.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.f5,1,$CLJS.c5,1],null)])))});
$CLJS.p5=function p5(a,b){b=$CLJS.bB(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.xB);var e=$CLJS.J.g(d,t5),f=$CLJS.J.g(d,$CLJS.e5),k=$CLJS.J.g(d,s5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Hk.g(d,s5),p5.g?p5.g(b,f):p5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,t5,!1);return p5.g?p5.g(l,m):p5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?v5.g(a,k5($CLJS.Hk.g(d,$CLJS.xB))):$CLJS.E.g($CLJS.zh.h(f),Kqa)?o5(a,d):Jqa(a,d)};
module.exports={compact_currency_options_js:Lqa,format_number:$CLJS.p5};