var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var uH,vH,xH,yH,Pfa,Qfa,Rfa,zH,wH;$CLJS.sH=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.tH=function(a,b){return $CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
uH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.qC(b,$CLJS.RC)?$CLJS.dH:$CLJS.qC(b,$CLJS.QD)?$CLJS.ZG:$CLJS.qC(b,$CLJS.qD)?$CLJS.aH:null;return $CLJS.n(b)?$CLJS.nF(b,a):!0};
vH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.sH(function(d){return $CLJS.qC($CLJS.LE(d),$CLJS.bD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Nk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.LE($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(uH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
xH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.qt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.st,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Dj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(vH(b))].join("")}],null),$CLJS.Te(vH)],null)],null)};
yH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fs,new $CLJS.h(null,1,[$CLJS.qt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null)],null)};
Pfa=function(a){return $CLJS.Qd($CLJS.qF,$CLJS.hf.g(function(b){var c=$CLJS.LE(b),d=$CLJS.qC(c,$CLJS.XE);b=d?$CLJS.nF($CLJS.kG,b):d;return $CLJS.n(b)?$CLJS.Pj:c},a))};Qfa=function(a){a=$CLJS.tH(function(b){return!$CLJS.qC(b,$CLJS.bD)},$CLJS.hf.g($CLJS.LE,a));return $CLJS.qC(a,$CLJS.XE)?$CLJS.Wj:a};
Rfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.qC($CLJS.LE(c),$CLJS.bD)},b))?Qfa(b):$CLJS.E.g(a,$CLJS.jt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.qC($CLJS.LE(c),$CLJS.RC)},b)||$CLJS.Qe(function(c){return $CLJS.qC($CLJS.LE(c),$CLJS.qD)},b))?$CLJS.bD:Pfa(b)};zH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.AH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);wH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.is,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,wH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qi,new $CLJS.h(null,1,[$CLJS.qt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.LE(a);return $CLJS.Qe(function(d){return uH(d,c)},b)}],null)],null));
$CLJS.X(zH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.Zn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)],null));
var Sfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.fs,new $CLJS.h(null,1,[$CLJS.qt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.jt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TE],null)],null)],
null);$CLJS.GG.g($CLJS.Wr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,xH($CLJS.Wr),yH($CLJS.Wr)],null));$CLJS.GG.g($CLJS.jt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,xH($CLJS.jt),Sfa,yH($CLJS.jt)],null));$CLJS.xF($CLJS.Xr,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,zH],null)]));$CLJS.xF($CLJS.BG,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ws,zH],null)]));
for(var BH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wr,$CLJS.jt,$CLJS.Xr],null)),CH=null,DH=0,EH=0;;)if(EH<DH){var Tfa=CH.X(null,EH);$CLJS.oF(Tfa,$CLJS.AH);EH+=1}else{var FH=$CLJS.y(BH);if(FH){var GH=FH;if($CLJS.Ad(GH)){var HH=$CLJS.lc(GH),Ufa=$CLJS.mc(GH),Vfa=HH,Wfa=$CLJS.D(HH);BH=Ufa;CH=Vfa;DH=Wfa}else{var Xfa=$CLJS.A(GH);$CLJS.oF(Xfa,$CLJS.AH);BH=$CLJS.B(GH);CH=null;DH=0}EH=0}else break}
$CLJS.KE.m(null,$CLJS.AH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Rfa(a,b)});$CLJS.vF($CLJS.HF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));$CLJS.oF($CLJS.HF,$CLJS.aF);
for(var IH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,$CLJS.yF,$CLJS.UF],null)),JH=null,KH=0,LH=0;;)if(LH<KH){var Yfa=JH.X(null,LH);$CLJS.vF(Yfa,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));LH+=1}else{var MH=$CLJS.y(IH);if(MH){var NH=MH;if($CLJS.Ad(NH)){var OH=$CLJS.lc(NH),Zfa=$CLJS.mc(NH),$fa=OH,aga=$CLJS.D(OH);IH=Zfa;JH=$fa;KH=aga}else{var bga=$CLJS.A(NH);$CLJS.vF(bga,$CLJS.H([$CLJS.jt,$CLJS.ND,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));IH=$CLJS.B(NH);JH=null;KH=0}LH=0}else break}
for(var PH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,$CLJS.eG,$CLJS.lG],null)),QH=null,RH=0,SH=0;;)if(SH<RH){var cga=QH.X(null,SH);$CLJS.vF(cga,$CLJS.H([$CLJS.jt,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));SH+=1}else{var TH=$CLJS.y(PH);if(TH){var UH=TH;if($CLJS.Ad(UH)){var VH=$CLJS.lc(UH),dga=$CLJS.mc(UH),ega=VH,fga=$CLJS.D(VH);PH=dga;QH=ega;RH=fga}else{var gga=$CLJS.A(UH);$CLJS.vF(gga,$CLJS.H([$CLJS.jt,$CLJS.wj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));PH=$CLJS.B(UH);QH=null;RH=0}SH=0}else break}$CLJS.vF($CLJS.EF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null)]));
$CLJS.KE.m(null,$CLJS.EF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.qC($CLJS.LE(b),$CLJS.wj)&&$CLJS.qC($CLJS.LE(a),$CLJS.wj)?$CLJS.wj:$CLJS.ND});