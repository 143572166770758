var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Bfa,Cfa,Dfa,Efa,Ffa,Gfa,Hfa,Ifa,Jfa;Bfa=function(a,b){a.sort(b||$CLJS.Da)};Cfa=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Da;Bfa(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.RG=function(a){return $CLJS.E.g(a,$CLJS.Jd)?$CLJS.Jd:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.SG=function(a,b){if($CLJS.y(b)){var c=$CLJS.we.h?$CLJS.we.h(b):$CLJS.we.call(null,b);Cfa(c,$CLJS.RG(a));return $CLJS.od($CLJS.y(c),$CLJS.pd(b))}return $CLJS.Kc};$CLJS.TG=new $CLJS.M(null,"second","second",-444702010);$CLJS.UG=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.VG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.WG=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.XG=new $CLJS.M(null,"year-of-era","year-of-era",682445876);Dfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Efa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.YG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.ZG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.$G=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Ffa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Gfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Hfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Ifa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.aH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.bH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.cH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.dH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
Jfa=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var eH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Fi,$CLJS.Gj,$CLJS.Fj,$CLJS.pi,$CLJS.mk,$CLJS.zi,$CLJS.ji,$CLJS.XG],null),fH=$CLJS.fh(eH),Kfa,Lfa,Mfa;$CLJS.X(Hfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid date extraction unit"],null)],null),fH));var gH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yt,$CLJS.oj,$CLJS.xj,$CLJS.oi,$CLJS.ji],null),hH=$CLJS.fh(gH);
$CLJS.X(Gfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid date truncation unit"],null)],null),hH));$CLJS.iH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.g(gH,eH));Kfa=$CLJS.fh($CLJS.iH);$CLJS.X($CLJS.$G,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid date bucketing unit"],null)],null),Kfa));var jH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UG,$CLJS.ij,$CLJS.vi],null),kH=$CLJS.fh(jH);
$CLJS.X(Ffa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid time extraction unit"],null)],null),kH));var lH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.WG,$CLJS.TG,$CLJS.Ti,$CLJS.qk],null),mH=$CLJS.fh(lH);$CLJS.X(Jfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid time truncation unit"],null)],null),mH));$CLJS.nH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.g(lH,jH));Lfa=$CLJS.fh($CLJS.nH);
$CLJS.X(Efa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid time bucketing unit"],null)],null),Lfa));$CLJS.oH=$CLJS.eg.j($CLJS.Cf,$CLJS.il.o(),$CLJS.gf.l(lH,gH,$CLJS.H([jH,eH])));Mfa=$CLJS.fh($CLJS.oH);$CLJS.X($CLJS.VG,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid datetime bucketing unit"],null)],null),$CLJS.oH));var Nfa=$CLJS.be.g(Mfa,$CLJS.ci);
$CLJS.X($CLJS.cH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid temporal bucketing unit"],null)],null),Nfa));$CLJS.pH=$CLJS.Zt.g(hH,mH);$CLJS.X($CLJS.YG,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid datetime truncation unit"],null)],null),$CLJS.pH));$CLJS.qH=$CLJS.Zt.g(fH,kH);$CLJS.X(Dfa,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid datetime extraction unit"],null)],null),$CLJS.qH));
var rH=$CLJS.be.g(hH,$CLJS.ji);$CLJS.X($CLJS.dH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid date interval unit"],null)],null),rH));$CLJS.X($CLJS.ZG,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid time interval unit"],null)],null),mH));var Ofa=$CLJS.Zt.g(rH,mH);$CLJS.X($CLJS.aH,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.qt,"Valid datetime interval unit"],null)],null),Ofa));
$CLJS.X(Ifa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ur,$CLJS.bH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,$CLJS.cH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ls,!0],null),$CLJS.Gs],null)],null));